import { Component, OnInit, Inject, Optional, ChangeDetectorRef, AfterViewChecked, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { NavbarService } from 'src/app/service/navbar.service';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-submission-withdrawal',
  templateUrl: './submission-withdrawal.component.html',
  styleUrls: ['./submission-withdrawal.component.scss']
})
export class SubmissionWithdrawalComponent implements OnInit, AfterViewChecked {
  public submission: Submission;
  public submissions: Array<Submission> = [];

  withdrawalForm: FormGroup;

  maxLength = 250;

  validWithdrawal: boolean = true;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private navbarService: NavbarService,
    private notificationService: NotificationService,
    public submissionService: SubmissionsService,
    public fb: FormBuilder,
    private currentDialogRef: MatDialogRef<SubmissionWithdrawalComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: { submission: Submission, submissions: Array<Submission> }
  ) { }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (this.data) {
        this.submission = this.data.submission;
        this.submissions = this.data.submissions;
    }

    this.withdrawalForm = this.fb.group({
      motive: ['', [Validators.maxLength(this.maxLength)]]
    });

    if (!this.submissions) this.validWithdrawal = !this.submission.withdrawable;
  }

  withdrawnSubmission() {
    const motive = this.withdrawalForm.value.motive;

    if (this.submissions?.length > 0) {
      this.multipleWithdrawnSubmission(motive);
      return;
    }

    this.navbarService.loadingStart();
    this.submissionService.withdrawSubmission(this.submission.id, motive).subscribe(s => {
      this.navbarService.loadingStop();
      this.notificationService.notify('submissions.withdrawal.successfully', { params: { id: s.id } });
      this.currentDialogRef.close(s);
    }, error => {
      this.navbarService.loadingStop();
      this.notificationService.notify('submissions.withdrawal.error');
      this.currentDialogRef.close();
    });
  }

  multipleWithdrawnSubmission(motive: string) {
    forkJoin(
      this.submissions.map(s => this.submissionService.withdrawSubmission(s.id, motive))
    ).subscribe(submissions => {
      next: if (submissions) this.notificationService.notify('submissions.withdrawal.successfully', {params: {id:this.transformIDsToString(submissions)}});
      error: err => { if (err) this.notificationService.notify('submissions.withdrawal.error') };
      complete: this.currentDialogRef.close(submissions);
    });
  }

  private transformIDsToString(submissions: Array<Submission>) {
    return submissions.map(s => s.id).join(', #');
  }

  closeDialog() {
    this.currentDialogRef.close();
  }
}
