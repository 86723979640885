import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ng2-cookies';
import { MaterialModule } from './material/material.module';
import { SigninComponent } from './landing/signin/signin.component';
import { SignupComponent } from './landing/signup/signup.component';
import { HomeComponent } from './home/home.component';
import { TextInputComponent } from './component/text-input/text-input.component';
import { PasswordInputComponent } from './component/password-input/password-input.component';
import { RecoverPasswordComponent } from './landing/recover-password/recover-password.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { LandingComponent } from './landing/landing.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BaseComponent } from './base/base.component';
import { EmailConfirmedComponent } from './landing/email-confirmed/email-confirmed.component';
import { SelectInputComponent } from './component/select-input/select-input.component';
import { SelectInputLoadEventComponent } from './component/select-input-le/select-input-le.component';
import { SelectInputLoadFlagComponent } from './component/select-input-flag/select-input-flag.component';
import { CalendarComponent } from './home/calendar/calendar.component';
import { SubmissionsComponent } from './home/submissions/submissions.component';
import { ReviewsComponent } from './home/reviews/reviews.component';
import { CommitteesComponent } from './home/committees/committees.component';
import { MyEventsComponent } from './home/my-events/my-events.component';
import { EventsComponent } from './home/events/events.component';
import { NotificationsComponent } from './home/notifications/notifications.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { EmailInputComponent } from './component/email-input/email-input.component';
import { Environment } from './environment/environment';
import { FabComponent } from './component/fab/fab.component';
import { AuthGuard } from './guard/auth.guard';
import { RedirectGuard } from './guard/redirect.guard';
import { ChangePasswordComponent } from './landing/change-password/change-password.component';
import { ProfileComponent } from './component/profile/profile.component';
import { AboutComponent } from './home/about/about.component';
import { HelpComponent } from './home/help/help.component';
import { SettingsComponent } from './home/settings/settings.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { SubmissionViewComponent } from './component/submission-view/submission-view.component';
import { SubmissionFormViewComponent } from './component/submission-form-view/submission-form-view.component';
import { TopicsComponent } from './component/topics/topics.component';
import { CreateSubmissionComponent } from './component/create-submission/create-submission.component';
import { MultilineTextInputComponent } from './component/multiline-text-input/multiline-text-input.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { IeeeElectronicCopyrightComponent } from './component/ieee-electronic-copyright/ieee-electronic-copyright.component';
import { FileDownloadComponent } from './component/file-download/file-download.component';
import { GoToElementComponent } from './component/go-to-element/go-to-element.component';
import { UserSearchComponent } from './component/user-search/user-search.component';
import { UserSearchConflictsComponent } from './component/user-search-conflicts/user-search-conflicts.component';
import { AffiliationSearchComponent } from './component/affiliation-search/affiliation-search.component';
import { SearchButtonComponent } from './component/search-button/search-button.component';
import { ReviewDelegateComponent } from './component/review-delegate/review-delegate.component';
import { UserSearchDialogComponent } from './component/user-search-dialog/user-search-dialog.component';
import { ReviewFormComponent } from './component/review-form/review-form.component';
import { CardViewComponent } from './component/card-view/card-view.component';
import { ChipCheckboxComponent } from './component/chip-checkbox/chip-checkbox.component';
import { SubmissionsTableComponent } from './component/submissions-table/submissions-table.component';
import { EventComponent } from './component/event/event.component';
import { EventViewComponent } from './component/event-view/event-view.component';
import { SubmissionStepComponent } from './component/submission-step/submission-step.component';
import { ErrorComponent } from './component/error/error.component';
import { UserFilterPipe } from './pipe/user-filter.pipe';
import { RadioButtonComponent } from './component/radio-button/radio-button.component';
import { CheckboxInputComponent } from './component/checkbox-input/checkbox-input.component';
import { CustomFormComponent } from './component/custom-form/custom-form.component';
import { EmptyStateComponent } from './component/empty-state/empty-state.component';
import { ReadableSizePipe } from './pipe/readable-size.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { CurrencyFormatPipe } from './pipe/currency.format.pipe';
import { NumericInputComponent } from './component/numeric-input/numeric-input.component';
import { ReviewViewComponent } from './component/review-view/review-view.component';
import { UserReviewComponent } from './component/user-review/user-review.component';
import { FixedTopProgressBarComponent } from './component/fixed-top-progress-bar/fixed-top-progress-bar.component';
import { FileRulesTableComponent } from './component/file-rules-table/file-rules-table.component';
import { SidesheetComponent } from './component/sidesheet/sidesheet.component';
import { ZerofillPipe } from './pipe/zerofill.pipe';
import { AvatarModule } from 'ngx-avatar';
import { AdminComponent } from './admin/admin.component';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { AdminEventsListComponent } from './admin/events/events.component';
import { AdminEventViewComponent } from './admin/event-admin/event-view/event-view.component';
import { EventAdminComponent } from './admin/event-admin/event-admin.component';
import { EventSubmissionsComponent } from './admin/event-admin/event-submissions/event-submissions.component';
import { EventPeopleComponent } from './admin/event-admin/event-people/event-people.component';
import { EventSettingsComponent } from './admin/event-admin/event-settings/event-settings.component';
import { SidebarItemComponent } from './component/sidebar-item/sidebar-item.component';
import { AdminEventSidebarComponent } from './component/admin-event-sidebar/admin-event-sidebar.component';
import { SidebarItemAdminComponent } from './component/sidebar-item-admin/sidebar-item-admin.component';
import { RouterLinkDisableDirective } from './directive/router-link-disable.directive';
import { EventsFilterPipe } from './pipe/events-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminNavbarComponent } from './component/admin-navbar/admin-navbar.component';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { CardPlaceholderComponent } from './component/card-placeholder/card-placeholder.component';
import { ReplacePipe } from './pipe/replace.pipe';
import { SubmissionsListFilterComponent } from './component/submissions-list-filter/submissions-list-filter.component';
import { RequestInterceptor } from './interceptor/http-request.interceptor';
import { ErrorSnackbarComponent } from './component/error-snackbar/error-snackbar.component';
import { SearchResultsComponent } from './home/search-results/search-results.component';
import { AdminEventCreateComponent } from './admin/events/create-event/create-event.component';
import { FormOverlayComponent } from './component/form-overlay/form-overlay.component';
import { DatepickerInputComponent } from './component/datepicker-input/datepicker-input.component';
import { DatetimepickerInputComponent } from './component/datetimepicker-input/datetimepicker-input.component';
import { ArrayChipInputComponent } from './component/array-chip-input/array-chip-input.component';
import { EventSearchComponent } from './component/event-search/event-search.component';
import { NotificationSnackbarComponent } from './component/notification-snackbar/notification-snackbar.component';
import { NotificationMenuComponent } from './component/notification-menu/notification-menu.component';
import { NotificationMenuTriggerComponent } from './component/notification-menu-trigger/notification-menu-trigger.component';
import { AdminGuard } from './guard/admin.guard';
import { ErrorViewComponent } from './component/error-view/error-view.component';
import { ViewPreferencesPickerComponent } from './component/view-preferences-picker/view-preferences-picker.component';
import { ChangePasswordProfileComponent } from './component/change-password-profile/change-password-profile.component';
import { PaperCardComponent } from './component/paper-card/paper-card.component';
import { PaperStatusComponent } from './component/paper-status/paper-status.component';
import { PaperListHeaderComponent } from './component/paper-list-header/paper-list-header.component';
import { ChangeProfilePictureComponent } from './component/change-profile-picture/change-profile-picture.component';
import { SignoutComponent } from './landing/signout/signout.component';
import { LoadingComponent } from './home/loading/loading.component';
import { AssignReviewersComponent } from './component/assign-reviewers/assign-reviewers.component';
import { CharacterCounterComponent } from './component/character-counter/character-counter.component';
import { RequestEventComponent } from './home/request/event/event.component';
import { EventFormComponent } from './component/event-form/event-form.component';
import { AdminEventRequestsComponent } from './admin/event-requests/event-requests.component';
import { ObjectToArrayPipe } from './pipe/object-to-array.pipe';
import { CheckEventComponent } from './component/check-event/check-event.component';
import { AdminLandingComponent } from './admin/landing/landing.component';
import { AdminEventRequestComponent } from './admin/event-requests/request/request.component';
import { SubmissionEditComponent } from './component/submission-edit/submission-edit.component';
import { EventSettingsEmailComponent } from './admin/event-admin/event-settings/event-settings-email/event-settings-email.component';
import { ActivateEmailComponent } from './landing/activate-email/activate-email.component';
import { EventTopicsComponent } from './admin/event-admin/event-settings/event-topics/event-topics.component';
import { EventChairsComponent } from './admin/event-admin/event-people/event-chairs/event-chairs.component';
import { EventTracksComponent } from './admin/event-admin/event-settings/event-tracks/event-tracks.component';
import { EventTracksEditComponent } from './component/event-tracks-edit/event-tracks-edit.component';
import { AdminEventNavbarComponent } from './component/admin-event-navbar/admin-event-navbar.component';
import { EventSendEmailComponent } from './admin/event-admin/event-people/event-send-email/event-send-email.component';
import { EventMembersComponent } from './admin/event-admin/event-people/event-members/event-members.component';
import { ConfirmTPCInviteComponent } from './landing/confirm-tpcinvite/confirm-tpcinvite.component';
import { PaperGroupsComponent } from './admin/event-admin/event-settings/paper-groups/paper-groups.component';
import { PaperTitlePipe } from './pipe/paper.title.pipe';
import { AdminCreateSubmissionComponent } from './component/admin-create-submission/admin-create-submission.component';
import { AdminSubmitSelectTrackComponent } from './admin/event-admin/admin-submit-select-track/admin-submit-select-track.component';
import { SearchResultsInEventComponent } from './admin/event-admin/search-results-in-event/search-results-in-event.component';
import { ReviewsConfigurationComponent } from './admin/event-admin/event-reviews/reviews-configuration/reviews-configuration.component';
import { PeopleCardComponent } from './component/people-card/people-card.component';
import { UserEventTopicsComponent } from './home/user-event-topics/user-event-topics.component';
import { EventUserTopicsComponent } from './admin/event-admin/event-people/event-user-topics/event-user-topics.component';
import { EventFormsComponent } from './admin/event-admin/event-settings/event-forms/event-forms.component';
import { TPCGroupsComponent } from './admin/event-admin/event-settings/tpc-groups/tpc-groups.component';
import { FormQuestionComponent } from './component/form-question/form-question.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EventTitlePipe } from './pipe/event.title.pipe';
import { ChairOrAdminGuard } from './guard/chair-admin.guard';
import { CustomFormTextQuestionComponent } from './component/custom-form-text-question/custom-form-text-question.component';
import { CustomFormCheckboxQuestionComponent } from './component/custom-form-checkbox-question/custom-form-checkbox-question.component';
import { CustomFormRadioQuestionComponent } from './component/custom-form-radio-question/custom-form-radio-question.component';
import { CustomFormLabelQuestionComponent } from './component/custom-form-label-question/custom-form-label-question.component';
import { ReportPrintingProblemComponent } from './component/report-printing-problem/report-printing-problem.component';
import { PreviewFormComponent } from './admin/event-admin/event-settings/event-forms/preview-form/preview-form.component';
import { EditableInputComponent } from './component/editable-input/editable-input.component';
import { PaperInfoComponent } from './component/paper-info/paper-info.component';
import { StartEditReviewComponent } from './component/start-edit-review/start-edit-review.component';
import { PaperReviewActionsComponent } from './component/paper-review-actions/paper-review-actions.component';
import { PaperReviewListComponent } from './component/paper-review-list/paper-review-list.component';
import { MissingManuscriptsComponent } from './admin/event-admin/event-settings/missing-manuscripts/missing-manuscripts.component';
import { UserViewComponent } from './admin/event-admin/event-people/user-view/user-view.component';
import { PrintingProblemComponent } from './component/printing-problem/printing-problem.component';
import { PaperReviewHistoryComponent } from './component/paper-review-history/paper-review-history.component';
import { SubmissionHistoryComponent } from './component/submission-history/submission-history.component';
import { ConflictInterestComponent } from './home/conflict-interest/conflict-interest.component';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { ReviewConfirmationDialogComponent } from './component/review-confirmation-dialog/review-confirmation-dialog.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorI18n } from './class/mat-paginator-i18n';
import { ShowAllSubmissionsComponent } from './admin/event-admin/event-submissions/show-all-submissions/show-all-submissions.component';
import { NgxPrintModule } from 'ngx-print';
import { ReviewStatusPerSubmissionComponent } from './admin/event-admin/event-reviews/review-status-per-submission/review-status-per-submission.component';
import { ReviewStatusPerUserComponent } from './admin/event-admin/event-reviews/review-status-per-user/review-status-per-user.component';
import { ConfirmReviewComponent } from './landing/confirm-review/confirm-review.component';
import { NotifyReviewersComponent } from './component/notify-reviewers/notify-reviewers.component';
import { AdminNotifyReviewersComponent } from './admin/event-admin/event-reviews/notify-reviewers/notify-reviewers.component';
import { NotifyAuthorsComponent } from './component/notify-authors/notify-authors.component';
import { AdminNotifyAuthorsComponent } from './admin/event-admin/event-submissions/notify-authors/notify-authors.component';
import { ClaimReviewComponent } from './component/claim-review/claim-review.component';
import { SubmissionFieldsFilterComponent } from './component/submission-fields-filter/submission-fields-filter.component';
import { FindReviewersByInterestComponent } from './component/find-reviewers-by-interest/find-reviewers-by-interest.component';
import { DelegateReviewComponent } from './component/delegate-review/delegate-review.component';
import { EventUserViewComponent } from './component/event-user-view/event-user-view.component';
import { CreateUserComponent } from './component/create-user/create-user.component';
import { TracksComponent } from './component/tracks/tracks.component';
import { FileRulesViewComponent } from './component/file-rules-view/file-rules-view.component';
import { EventBackButtonComponent } from './component/event-back-button/event-back-button.component';
import { EditUserAffiliationComponent } from './component/edit-user-affiliation/edit-user-affiliation.component';
import { SubmissionRankingComponent } from './admin/event-admin/event-submissions/submission-ranking/submission-ranking.component';
import { AssignReviewSuggestionComponent } from './admin/event-admin/event-reviews/assign-review-suggestion/assign-review-suggestion.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { SubmissionWithdrawalComponent } from './component/submission-withdrawal/submission-withdrawal.component';
import { SubmissionDeleteComponent } from './component/submission-delete/submission-delete.component';
import { DiscussionMessagesComponent } from './component/discussion-messages/discussion-messages.component';
import { EmailPreviewComponent } from './component/email-preview/email-preview.component';
import { SubmissionFilesComponent } from './admin/event-admin/event-submissions/submission-files/submission-files.component';
import { from, Observable } from 'rxjs';
import { AdminBibtexExportComponent } from './admin/event-admin/event-submissions/bibtex-export/bibtex-export.component';
import { ClipboardModule } from 'ngx-clipboard';
import { RouteTitleGuard } from './guard/route-title.guard';
import { AdminDefaultEmailTemplatesComponent } from './admin/admin-default-email-templates/admin-default-email-templates.component';
import { RebuttalComponent } from './component/rebuttal/rebuttal.component';
import { AdministratorsComponent } from './admin/administrators/administrators.component';
import { MergeUsersComponent } from './admin/merge-users/merge-users.component';
import { DeletedSubmissionsComponent } from './admin/deleted-submissions/deleted-submissions.component';
import { AdminProxyNavbarComponent } from './component/admin-proxy-navbar/admin-proxy-navbar.component';
import { AdminEventWizardComponent } from './admin/event-admin/event-wizard/event-wizard.component';
import { FileRulesCompactComponent } from './component/file-rules-compact/file-rules-compact.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ChartistModule } from 'ng-chartist';
import { ChartComponent } from './component/chart-component/chart-component.component';
import { DashboardSettingsComponent } from './admin/event-admin/event-settings/dashboard-settings/dashboard-settings.component';
import { AdminEventSubconferencesComponent } from './admin/event-admin/event-subconferences/event-subconferences.component';
import { MarkdownModule } from 'ngx-markdown';
import { ShowControlsErrorsComponent } from './component/show-controls-errors/show-controls-errors.component';
import { UserDashboardComponent } from './home/user-dashboard/user-dashboard.component';
import { ReviewsSmallTableComponent } from './component/reviews-small-table/reviews-small-table.component';
import { SessionsComponent } from './component/sessions/sessions.component';
import { CalendarViewComponent } from './admin/event-admin/event-sessions/calendar-view/calendar-view.component';
import { EventSessionsComponent } from './admin/event-admin/event-sessions/sessions/sessions.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { NgxMailtoModule } from 'ngx-mailto';
import * as moment from 'moment';
import { GeneralSessionDialogComponent } from './component/general-session-dialog/general-session-dialog.component';
import { FormSessionDialogComponent } from './component/form-session-dialog/form-session-dialog.component';
import { FormSubSessionDialogComponent } from './component/sub-session-dialog/sub-session-dialog.component';
import { EventProgramComponent } from './admin/event-admin/event-sessions/program/event-program.component';
import { EmailStatusComponent } from './admin/event-admin/event-settings/email-status/email-status.component';
import { CountryPipe } from './pipe/country.pipe';
import { LandingProfileComponent } from './component/landing-profile/landing-profile.component';
import { LoginHistoryComponent } from './component/login-history/login-history.component';
import { DateFormatPipe } from '../app/pipe/date.format.pipe';
import ptBRLocale from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SubSessionTextFilterPipe } from './pipe/session-sub-filter';
import { SubSessionStatusFilterPipe } from './pipe/session-status-sub-filter';
import { AvatarProfileComponent } from './component/avatar-profile/avatar-profile.component';
import { SubmissionStatisticsComponent } from './admin/event-admin/event-submissions/submission-statistics/submission-statistics.component';
import { SubmissionTopicStatisticsComponent } from './component/submission-topic-statistics/submission-topic-statistics.component';
import { SubmissionTracksStatisticsComponent } from './component/submission-tracks-statistics/submission-tracks-statistics.component';
import { SubmissionAuthorStatisticsComponent } from './component/submission-author-statistics/submission-author-statistics.component';
import { InputFilterAutocompleteComponent } from './component/input-filter-autocomplete/input-filter-autocomplete.component';
import { EventPublicationComponent } from './admin/event-admin/event-publication/event-publication/event-publication.component';
import { EventPublicationStatusComponent } from './admin/event-admin/event-publication/event-publication-status/event-publication-status.component';
import { EventPublicationProceedingsComponent } from './admin/event-admin/event-publication/event-publication-proceedings/event-publication-proceedings.component';
import { CustomControlFieldEditComponent } from './component/custom-control-field-edit/custom-control-field-edit.component';
import { DialogTimezoneComponent } from './component/dialog-timezone/dialog-timezone.component';
import { CreateAffiliationComponent } from './component/create-affiliation/create-affiliation.component';
import { SubmissionsListTableComponent } from './component/submissions-list-table/submissions-list-table.component';
import { ProceedingStepComponent } from './component/proceeding-step/proceeding-step.component';
import { ProceedingContentItemComponent } from './component/proceeding-content-item/proceeding-content-item.component';
import { FileRulesCardComponent } from './component/file-rules-card/file-rules-card.component';
import { NewGroupDialogComponent } from './component/new-group-dialog/new-group-dialog.component';
import { SelectInputIconComponent } from './component/select-input-icon/select-input-icon.component';
import { FileRulesCardInfoDialogComponent } from './component/file-rules-card-info-dialog/file-rules-card-info-dialog.component';
import { ArticlesListCardDialogComponent } from './component/articles-list-card-dialog/articles-list-card-dialog.component';
import { SelectInputGroupComponent } from './component/select-input-group/select-input-group.component';
import { PublicationFixedControlComponent } from './component/publication-fixed-control/publication-fixed-control.component';
import { MenuSubmissionListComponent } from './component/menu-submission-list/menu-submission-list.component';
import { NotifyPendingsComponent } from './component/notify-pendings/notify-pendings.component';
import { ProxyHistoryComponent } from './component/proxy-history/proxy-history.component';
import { DisplayPaperTitleComponent } from './component/display-paper-title/display-paper-title.component';
import { DialogCreateUserComponent } from './component/dialog-create-user/dialog-create-user.component';
import { PrintingProblemsComponent } from './component/printing-problems/printing-problems.component';
import { ProceedingIndexItemComponent } from './component/proceeding-index-item/proceeding-index-item.component';
import { TimeZoneInputComponent } from './component/time-zone-input/time-zone-input.component';
import { PaperPublicationInfoComponent } from './component/paper-publication-info/paper-publication-info.component';
import { ProceedingAuthorIndexItemComponent } from './component/proceeding-author-index-item/proceeding-author-index-item.component';
import { ReviewerInvitationComponent } from './component/reviewer-invitation/reviewer-invitation.component';
import { InputDropFileComponent } from './component/input-drop-file/input-drop-file.component';
import { ChangeTracksDialogComponent } from './component/change-tracks-dialog/change-tracks-dialog.component';
import { ReviewReclaimComponent } from './component/review-reclaim/review-reclaim.component';
import { AdminUserSearchComponent } from './admin/user-search/user-search.component';
import { SubmissionsListTableV2Component } from './component/submissions-list-table-v2/submissions-list-table-v2.component';
import { SubmissionsFieldFilterV2Component } from './component/submissions-field-filter-v2/submissions-field-filter-v2.component';
import { ChartComponentV2Component } from './component/chart-component-v2/chart-component-v2.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExportSubmissionsComponent } from './admin/event-admin/event-submissions/export-submissions/export-submissions.component';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { MathCaptchaComponent } from './component/math-captcha/math-captcha.component';


export function momentAdapterFactory() {
  return adapterFactory(moment);
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}

const API = (new URL(Environment.urls.API));

export function getJwtConfig() {
  return {
    tokenGetter: jwtTokenGetter,
    allowedDomains: [API.host],
    disallowedRoutes: [
      Environment.urls.API + '/core/auth/login/',
      Environment.urls.API + '/core/auth/token/refresh/',
      Environment.urls.API + '/core/auth/password/reset/confirm/',
      Environment.urls.API + '/core/auth/password/reset/',
      Environment.urls.API + '/core/auth/activate/',
      Environment.urls.API + '/core/user/',
      new RegExp(escapeRegExp(Environment.urls.API + '/core/institution/search/?search=') + '.*'),
      Environment.urls.API + '/core/institution/',
      Environment.urls.API + '/core/countries/'
    ]
  };
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

registerLocaleData(ptBRLocale);

export function getLocale() {
  return localStorage.getItem('language') || 'en';
}

@NgModule({
  declarations: [
    SignoutComponent,
    LoadingComponent,
    SigninComponent,
    SignupComponent,
    HomeComponent,
    TextInputComponent,
    PasswordInputComponent,
    RecoverPasswordComponent,
    NavbarComponent,
    SidebarComponent,
    LandingComponent,
    BaseComponent,
    EmailConfirmedComponent,
    SelectInputComponent,
    SelectInputLoadEventComponent,
    SelectInputLoadFlagComponent,
    CalendarComponent,
    SubmissionsComponent,
    ReviewsComponent,
    CommitteesComponent,
    MyEventsComponent,
    EventsComponent,
    NotificationsComponent,
    EmailInputComponent,
    FabComponent,
    SubmissionViewComponent,
    SubmissionFormViewComponent,
    ProfileComponent,
    CalendarViewComponent,
    AboutComponent,
    HelpComponent,
    SettingsComponent,
    ChangePasswordComponent,
    TopicsComponent,
    CreateSubmissionComponent,
    MultilineTextInputComponent,
    FileUploadComponent,
    IeeeElectronicCopyrightComponent,
    FileDownloadComponent,
    GoToElementComponent,
    ZerofillPipe,
    UserSearchComponent,
    UserSearchConflictsComponent,
    SearchButtonComponent,
    ReviewDelegateComponent,
    UserSearchDialogComponent,
    ReviewFormComponent,
    CardViewComponent,
    ChipCheckboxComponent,
    RadioButtonComponent,
    CheckboxInputComponent,
    CustomFormComponent,
    AffiliationSearchComponent,
    SubSessionTextFilterPipe,
    SubSessionStatusFilterPipe,
    SubmissionsTableComponent,
    EventComponent,
    EventViewComponent,
    SubmissionStepComponent,
    ErrorComponent,
    UserFilterPipe,
    EmptyStateComponent,
    ReadableSizePipe,
    TruncatePipe,
    CurrencyFormatPipe,
    NumericInputComponent,
    ReviewViewComponent,
    UserReviewComponent,
    FixedTopProgressBarComponent,
    FileRulesTableComponent,
    SidesheetComponent,
    CardPlaceholderComponent,
    AdminComponent,
    AdminEventsListComponent,
    AdminEventViewComponent,
    EventAdminComponent,
    EventSubmissionsComponent,
    EventPeopleComponent,
    EventSettingsComponent,
    SidebarItemComponent,
    AdminEventSidebarComponent,
    SidebarItemAdminComponent,
    RouterLinkDisableDirective,
    EventsFilterPipe,
    ReplacePipe,
    SubmissionsListFilterComponent,
    ErrorSnackbarComponent,
    SearchResultsComponent,
    AdminNavbarComponent,
    ErrorSnackbarComponent,
    SearchResultsComponent,
    AdminEventCreateComponent,
    FormOverlayComponent,
    DatepickerInputComponent,
    DatetimepickerInputComponent,
    ArrayChipInputComponent,
    EventSearchComponent,
    NotificationSnackbarComponent,
    NotificationMenuComponent,
    NotificationMenuTriggerComponent,
    SubmissionsListFilterComponent,
    ViewPreferencesPickerComponent,
    ChangePasswordProfileComponent,
    PaperCardComponent,
    PaperStatusComponent,
    PaperListHeaderComponent,
    ChangeProfilePictureComponent,
    ErrorViewComponent,
    AssignReviewersComponent,
    CharacterCounterComponent,
    SubmissionEditComponent,
    NotificationMenuTriggerComponent,
    RequestEventComponent,
    EventFormComponent,
    AdminEventRequestsComponent,
    ObjectToArrayPipe,
    CheckEventComponent,
    AdminLandingComponent,
    AdminEventRequestComponent,
    EventSettingsEmailComponent,
    ActivateEmailComponent,
    EventTopicsComponent,
    EventChairsComponent,
    EventTracksComponent,
    EventTracksEditComponent,
    AdminEventNavbarComponent,
    EventSendEmailComponent,
    EventMembersComponent,
    ConfirmTPCInviteComponent,
    AdminCreateSubmissionComponent,
    AdminSubmitSelectTrackComponent,
    SearchResultsInEventComponent,
    PeopleCardComponent,
    PaperGroupsComponent,
    PaperTitlePipe,
    UserEventTopicsComponent,
    EventUserTopicsComponent,
    PaperTitlePipe,
    EventTitlePipe,
    EventFormsComponent,
    TPCGroupsComponent,
    FormQuestionComponent,
    ReviewsConfigurationComponent,
    CustomFormTextQuestionComponent,
    CustomFormCheckboxQuestionComponent,
    CustomFormRadioQuestionComponent,
    CustomFormLabelQuestionComponent,
    ReportPrintingProblemComponent,
    PaperInfoComponent,
    StartEditReviewComponent,
    PaperReviewActionsComponent,
    PaperReviewListComponent,
    PreviewFormComponent,
    EditableInputComponent,
    SubmissionHistoryComponent,
    PreviewFormComponent,
    EditableInputComponent,
    PrintingProblemComponent,
    MissingManuscriptsComponent,
    UserViewComponent,
    PaperReviewHistoryComponent,
    ShowAllSubmissionsComponent,
    ConflictInterestComponent,
    ConfirmationDialogComponent,
    ReviewConfirmationDialogComponent,
    ReviewStatusPerSubmissionComponent,
    ReviewStatusPerUserComponent,
    ConfirmReviewComponent,
    NotifyReviewersComponent,
    AdminNotifyReviewersComponent,
    NotifyAuthorsComponent,
    AdminNotifyAuthorsComponent,
    SubmissionFieldsFilterComponent,
    FindReviewersByInterestComponent,
    ClaimReviewComponent,
    DelegateReviewComponent,
    EventUserViewComponent,
    CreateUserComponent,
    TracksComponent,
    FileRulesViewComponent,
    EventBackButtonComponent,
    EditUserAffiliationComponent,
    SubmissionRankingComponent,
    AssignReviewSuggestionComponent,
    SubmissionWithdrawalComponent,
    SubmissionDeleteComponent,
    DiscussionMessagesComponent,
    EmailPreviewComponent,
    SubmissionFilesComponent,
    AdminBibtexExportComponent,
    AdminDefaultEmailTemplatesComponent,
    RebuttalComponent,
    AdministratorsComponent,
    MergeUsersComponent,
    DeletedSubmissionsComponent,
    AdminProxyNavbarComponent,
    AdminEventWizardComponent,
    FileRulesCompactComponent,
    ChartComponent,
    DashboardSettingsComponent,
    AdminEventSubconferencesComponent,
    ShowControlsErrorsComponent,
    UserDashboardComponent,
    ReviewsSmallTableComponent,
    SessionsComponent,
    EventSessionsComponent,
    FormSessionDialogComponent,
    FormSubSessionDialogComponent,
    GeneralSessionDialogComponent,
    EventProgramComponent,
    EmailStatusComponent,
    CountryPipe,
    LandingProfileComponent,
    LoginHistoryComponent,
    DateFormatPipe,
    AvatarProfileComponent,
    SubmissionStatisticsComponent,
    SubmissionTopicStatisticsComponent,
    SubmissionTracksStatisticsComponent,
    SubmissionAuthorStatisticsComponent,
    InputFilterAutocompleteComponent,
    DialogTimezoneComponent,
    CreateAffiliationComponent,
    EventPublicationComponent,
    EventPublicationStatusComponent,
    EventPublicationProceedingsComponent,
    CustomControlFieldEditComponent,
    DialogTimezoneComponent,
    SubmissionsListTableComponent,
    ProceedingStepComponent,
    ProceedingContentItemComponent,
    FileRulesCardComponent,
    NewGroupDialogComponent,
    SelectInputIconComponent,
    SelectInputGroupComponent,
    PublicationFixedControlComponent,
    FileRulesCardInfoDialogComponent,
    ArticlesListCardDialogComponent,
    SelectInputGroupComponent,
    MenuSubmissionListComponent,
    NotifyPendingsComponent,
    ProxyHistoryComponent,
    DisplayPaperTitleComponent,
    DialogCreateUserComponent,
    PrintingProblemsComponent,
    ProceedingIndexItemComponent,
    TimeZoneInputComponent,
    PaperPublicationInfoComponent,
    ProceedingAuthorIndexItemComponent,
    ReviewerInvitationComponent,
    ReviewReclaimComponent,
    InputDropFileComponent,
    AdminUserSearchComponent,
    ChangeTracksDialogComponent,
    SubmissionsListTableV2Component,
    SubmissionsFieldFilterV2Component,
    ChartComponentV2Component,
    ExportSubmissionsComponent,
    MathCaptchaComponent
  ],
  imports: [
    DragDropModule,
    NgxMailtoModule,
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: getJwtConfig()
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    InfiniteScrollModule,
    ContentLoaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ClickOutsideModule,
    EcoFabSpeedDialModule,
    NgxPrintModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NgxMatNativeDateModule,
    ClipboardModule,
    MatSelectFilterModule,
    ScrollingModule,
    ChartistModule,
    MarkdownModule.forRoot({ loader: HttpClientModule }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    ImageCropperModule,
    HammerModule,
    NgxChartsModule,
    RecaptchaModule
  ],
  providers: [
    AuthGuard,
    RedirectGuard,
    AdminGuard,
    CookieService,
    Title,
    RouteTitleGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    ChairOrAdminGuard,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18n
    },
    {
      provide: MAT_DATE_LOCALE,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_ADAPTER_OPTIONS
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: getLocale()
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LfjYVsqAAAAAONyulaTe9F-XuGSVZbVJOiLfSKR'
      } as RecaptchaSettings
    }
  ],
  entryComponents: [
    SidesheetComponent,
    ErrorSnackbarComponent,
    NotificationSnackbarComponent,
    ChangePasswordProfileComponent,
    CustomFormTextQuestionComponent,
    CustomFormCheckboxQuestionComponent,
    CustomFormRadioQuestionComponent,
    ReviewConfirmationDialogComponent,
    ConfirmationDialogComponent,
    SubmissionWithdrawalComponent,
    SubmissionDeleteComponent,
    FormSessionDialogComponent,
    FormSubSessionDialogComponent,
    GeneralSessionDialogComponent,
    CustomControlFieldEditComponent,
    NewGroupDialogComponent,
    FileRulesCardInfoDialogComponent,
    ArticlesListCardDialogComponent,
    DialogCreateUserComponent,
    ReviewDelegateComponent,
    UserSearchDialogComponent,
    PrintingProblemsComponent,
    CreateAffiliationComponent,
    ChangeTracksDialogComponent,
    ReviewReclaimComponent
  ],
  bootstrap: [BaseComponent]
})

export class AppModule { }
