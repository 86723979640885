import { Component, OnInit, HostBinding, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from 'src/app/model/user';
import { SidebarService } from '../../service/sidebar.service';
import { SidebarItem, AdminSidebarItem } from '../../model/sidebar-item';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { NotificationService } from '../../service/notification.service';
import { ThemePalette } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { InfoService } from '../../service/info.service';
import { AdminService } from '../../service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-event-sidebar',
  templateUrl: './admin-event-sidebar.component.html',
  styleUrls: ['./admin-event-sidebar.component.scss']
})
export class AdminEventSidebarComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('content', { static: true }) contentNav: MatSidenav;
  @Input() topItems: SidebarItem[] = [];
  @Input() bottomItems: SidebarItem[] = [];
  selectedItem: SidebarItem;
  searchForm: FormGroup;

  notificationMenu: MatMenu;

  selectedItemMenu = false;
  selectedElement: string;
  userIsAdmin = false;
  user: User;

  constructor(
    public router: Router,
    public authService: AuthService,
    private fb: FormBuilder,
    public notificationService: NotificationService,
    public infoService: InfoService,
    private userService: UserService,
    public adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.notificationService.menuTrigger = this.menuTrigger;
    this.notificationService.refreshNotifications();
    this.authService.directUserIsAdmin().subscribe(userIsAdmin => {
      this.userIsAdmin = userIsAdmin;
    });
    this.userService.getUserFromToken().subscribe( user => {
      this.user = user;
    });

    this.adminService.sidebar = this;
    setTimeout(() => {
      this.initSidenav();
    });
  }

  initSidenav() {
    if (this.topItems) {
      // Checks if is inside a homeItem url of the topItems
      let mainItem: string;
      let homeItem = false;
      this.topItems[0].children.forEach(child => {
        if (child.link == this.router.url) {
          homeItem = true;
        }
      });

      // If the current url isn't on the home item, get the mainItem by the current url
      if (homeItem){
        mainItem = this.topItems[0].link;
      } else {
        mainItem = this.router.url.split('/').slice(0,4).join('/');
      } 

      // Specific cases when refreshing, the url is unique and the mainItem wouldn't be found in the topItems
      if(this.router.url.endsWith('/tracks')){
        mainItem = 'submissions'
      }else if(this.router.url.endsWith('submission-files') || this.router.url.endsWith('bibtex-export')){
        mainItem = 'publication'
      }
    
      this.selectedItem = this.topItems.find((item) => item.link.includes(mainItem));
      if(this.selectedItem){
        this.selectedItem.selected = true;
      }
    }
    this.set_go_to_element();
  }

  changeContent(newSelectedItem: SidebarItem) {
    let shouldClose = false;
    if (this.selectedItem === newSelectedItem) {
      this.selectedItem.selected = !this.selectedItem.selected;
      shouldClose = true;
    } else {
      if (this.selectedItem) {
        this.selectedItem.selected = false;
      }
      this.selectedItem = newSelectedItem;
      this.selectedItem.selected = true;
    }

    if (!this.contentNav.opened && this.selectedItem.children && this.selectedItem.children.length > 0) {
      this.contentNav.open();
    } else if (!(this.contentNav.opened && this.selectedItem.children && this.selectedItem.children.length > 0) || shouldClose) {
      this.contentNav.close();
      shouldClose = false;
    }
    this.set_go_to_element();
  }

  set_go_to_element() {
    this.selectedElement = this.selectedItem?.link.split('/').pop();
    switch (this.selectedElement) {
      case 'submissions':
        this.selectedItemMenu = true;
        break;

      case 'people':
        this.selectedItemMenu = true;
        break;

      default:
        this.selectedItemMenu = false;
        break;
    }

    this.searchForm = this.fb.group({
      elementId: ['']
    });
  }

  subitemSelect(subitem: SidebarItem) {
    if (this.isMobile) {
      this.contentNav.close();
    }
  }

  openSidenav() {
    let open: boolean;
    if (!this.selectedItem) {
      if(this.isMobile){
        open = false;
      } else {
        open = true;
      }
    } else {
      if (!!this.selectedItem && !!this.selectedItem.children && this.selectedItem.children.length > 0) {
        open = true;
      } else {
        open = false;
      }
    }
    return open;
  }

  get isMobile(): boolean {
    return window.innerWidth < 768;
  }

  canShow(item: SidebarItem) {
    return this.userIsAdmin || !(item.label as string).endsWith('proceedings');
  }
}
