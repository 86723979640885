import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { AdminService } from '../../service/admin.service';
import { User } from '../../model/user';
import { Event } from '../../model/event';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../service/user.service';
import { EventsService } from '../../service/events.service';
import { SubmissionsService } from '../../service/submissions.service';
import { SHOW_ALL_PAGINATION_OPTIONS } from '../../model/pagination.options';
import { SubmissionFilter } from 'src/app/model/submission.filter';
import { Submission, Review } from 'src/app/model/paper';
import { ReviewsService } from '../../service/reviews.service';
import { UserEventRole } from '../../enum/user.event.role';
import { map } from 'rxjs/operators';
import { zip, Observable, Subscription, of, forkJoin } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { AssignReview } from 'src/app/model/assign.review';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AuthService } from 'src/app/service/auth.service';
import { slideOutAnimation } from 'src/app/animations/slideInOut';

//import { Session } from 'protractor';
import { Session } from 'src/app/model/session';
import { TimezoneService } from 'src/app/service/timezone.service';

interface ExtendedCalendarEventSessions extends CalendarEvent {
  event: any;
  title: string;
  subtitle: string;
  url: string;
  room: string;
  submissions: Array<Submission>;
}

class EventRole {
  chair = false;
  committee = false;
  publication_chair = false;
  reviewer = false;

  constructor(
    public event: Event
  ) { }

  setRole(role: UserEventRole) {
    switch (role) {
      case UserEventRole.CHAIR:
        this.chair = true;
        break;
      case UserEventRole.COMMITTEE:
        this.committee = true;
        break;
      case UserEventRole.PUBLICATION_CHAIR:
        this.publication_chair = true;
        break;
      case UserEventRole.REVIEWER:
        this.reviewer = true;
        break;
      default:
        break;
    }
  }
}

const BUTTONS = {
  down: 'fa-chevron-down',
  up: 'fa-chevron-up'
};

class RequestedSubmission extends Submission {
  reviewers: number;
  hasUserReview: boolean;

  assignToUser = false;
}

@Component({
  selector: 'app-event-user-view',
  templateUrl: './event-user-view.component.html',
  styleUrls: ['./event-user-view.component.scss'],
  animations: [
    slideOutAnimation
  ]
})
export class EventUserViewComponent implements OnInit, OnDestroy {
  @Input() event: Event;
  @Input() user: User;

  @Input() showRequested = false;
  @Input() showRelatedTo = false;

  isAdminOrChair = false;

  submissionsRequested: Array<RequestedSubmission>;
  submissionsAuthored: Array<Submission>;
  sessionSubmissions: Array<Submission>;
  assignedToOthers: Array<Review>;
  assignedToUser: Array<Review>;
  relatedToEvent: Array<EventRole>;
  isComittee: Boolean;
  showRebuttal = true;
  eventTimeZone: string;
  reviewConfig: EventReviewConfiguration;

  eventReviews: Review[];

  toggleButtons = {
    submissionsRequested: BUTTONS.down,
    submissionsAuthored: BUTTONS.down,
    assignedToOthers: BUTTONS.down,
    SessionSubmissions: BUTTONS.down,
    assignedToUser: BUTTONS.down,
    relatedToEvent: BUTTONS.down
  };

  loading = {
    submissionsRequested: false,
    submissionsAuthored: false,
    assignedToOthers: false,
    SessionSubmissions: false,
    assignedToUser: false,
    relatedToEvent: false,
    relatedToEventIndex: 0
  };

  assignDateForm: FormGroup;
  assignDateLink;
  subscriptions: Subscription[] = [];

  constructor(
    private eventsService: EventsService,
    private reviewConfigurationService: ReviewsConfigurationService,
    private submissionsService: SubmissionsService,
    private reviewsService: ReviewsService,
    private infoService: InfoService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private authService: AuthService,
    private tzService: TimezoneService
  ) { }

  ngOnInit(): void {
    this.eventsService.getUserIsUserEvent(this.event.id, UserEventRole.COMMITTEE, this.user.id).subscribe(bool => {
      this.isComittee = bool;
    });

    this.authService.userIsAdminOrChair(this.event.id).subscribe(isAdminOrChair => {
      this.isAdminOrChair = isAdminOrChair;
      if (!this.isAdminOrChair) {
        this.toggleButtons.assignedToOthers = BUTTONS.up;
        this.getAssignedToOthers();
      }
    });

    if (this.isAdminOrChair) {
      this.assignDateLink = {
        label: 'forms.buttons.assign-review-date',
        route: `/admin/${this.event.id}/reviews/configuration`,
        align: 'start'
      };
    }

    this.reviewConfigurationService.getReviewsConfiguration(this.event.id).subscribe(configuration => {
      this.reviewConfig = configuration;
      this.assignDateForm = this.fb.group({
        dueAt: [configuration.conferenceDateDue ?.toDate()],
        timeZone: [this.tzService.browserTimeZone]
      });
    });

    this.toggleButtons.submissionsAuthored = BUTTONS.up;
    this.getSubmissionsAuthored();

    this.toggleButtons.assignedToUser = BUTTONS.up;
    this.getAssignedToUser();
    this.getSessionSubmissions()
    this.eventTimeZone = this.event.eventInfo?.timeZone;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public clearComponent() {
    this.submissionsRequested = undefined;
    this.submissionsAuthored = undefined;
    this.relatedToEvent = undefined;
    this.assignedToUser = undefined;
    this.assignedToOthers = undefined;

    this.loading = {
      submissionsRequested: false,
      submissionsAuthored: false,
      SessionSubmissions: false,
      assignedToOthers: false,
      assignedToUser: false,
      relatedToEvent: false,
      relatedToEventIndex: 0
    };

    this.toggleButtons = {
      submissionsRequested: BUTTONS.down,
      submissionsAuthored: BUTTONS.down,
      SessionSubmissions: BUTTONS.down,
      assignedToOthers: BUTTONS.down,
      assignedToUser: BUTTONS.down,
      relatedToEvent: BUTTONS.down
    };
  }

  getSubmissionsRequested() {
    if (!this.submissionsRequested) {
      this.loading.submissionsRequested = true;
      this.submissionsService.getSubmissionsToClaim(this.event.id, this.user.id).subscribe(submissions => {
        this.submissionsRequested = <RequestedSubmission[]>submissions.filter(s => s.userClaims.find(u =>
          (typeof u === 'number' ? u : u.id) === this.user.id));

        if (this.submissionsRequested.length === 0) {
          this.loading.submissionsRequested = false;
          this.toggleButtons.submissionsRequested = BUTTONS.up;

          this.submissionsRequested = [];
          return;
        }

        forkJoin(this.submissionsRequested.map(s => this.reviewsService.getReviewsBySubmissionId(s.id))).subscribe(submissionReviews => {
          submissionReviews.forEach((reviews, index) => {
            const submission = this.submissionsRequested[index];
            submission.reviewers = reviews.length;
            submission.hasUserReview = !!reviews.find(review => review.userId === this.user.id);
          });

          this.loading.submissionsRequested = false;
          this.toggleButtons.submissionsRequested = BUTTONS.up;
        });
      });
    } else {
      this.submissionsRequested = undefined;
      this.toggleButtons.submissionsRequested = BUTTONS.down;
    }
  }

  assignSubmissions() {
    const submissions = this.submissionsRequested.filter(s => s.assignToUser);
    const dueAt = this.assignDateForm.value.dueAt;

    const reviews = submissions.map(submission => {
      const review = new AssignReview();

      review.submission = submission.id;
      review.assignedBy = this.infoService.user.id;
      review.user = this.user.id;
      review.dueAt = dueAt;

      return review;
    });

    forkJoin(reviews.map(r => this.reviewsService.createReview(r))).subscribe(createdReviews => {
      if (createdReviews ?.length > 0) {
        this.notificationService.notify('messages.assigned-user-review', { params: { user: createdReviews[0].userId } });

        if (this.assignedToUser ?.length > 0) {
          this.assignedToUser.push(...createdReviews);
        }
        this.submissionsRequested = undefined;
        this.getSubmissionsRequested();
      }
    });
  }

  getSubmissionsAuthored() {
    if (!this.submissionsAuthored) {
      this.loading.submissionsAuthored = true;
      this.submissionsService.getUserIDSubmissionsFromEvent(this.event.id, this.user.id).subscribe(p => {
        const submissions = p.items as Submission[];
        this.submissionsAuthored = submissions.map(e => {
          e.event = this.event;
          e.track = this.event.tracks.find(t => e.trackID === t.id);
          return e;
        });
        this.loading.submissionsAuthored = false;
        this.toggleButtons.submissionsAuthored = BUTTONS.up;
      });
    } else {
      this.submissionsAuthored = undefined;
      this.toggleButtons.submissionsAuthored = BUTTONS.down;
    }
  }

  getSessionSubmissions() {
    if (!this.sessionSubmissions) {
      this.loading.SessionSubmissions = true;
      this.submissionsService.getSessionsSubmissionsBySessionChair(this.event.id, this.user.id).subscribe(p => {
        const submissions = p.items as Submission[];
        this.sessionSubmissions = submissions.map(e => {
          e.event = this.event;
          e.track = this.event.tracks.find(t => e.trackID === t.id);
          return e;
        });
        this.sessionSubmissions = this.sessionSubmissions.sort(function (a, b):number {
          if((a.session as unknown as Session).id < (b.session as unknown as Session).id){
            return -1;
          }else if((a.session as unknown as Session).id === (b.session as unknown as Session).id){
            if(a.id < b.id){
              return -1;
            }else{
              return +1;
            }
          }else{
            return +1;
          }
        });
        this.loading.SessionSubmissions = false;
        this.toggleButtons.SessionSubmissions = BUTTONS.up;
      });
    } else {
      this.sessionSubmissions = undefined;
      this.toggleButtons.SessionSubmissions = BUTTONS.down;
    }
  }


  public updateSubmission(updatedSubmission: Submission): void {
    if (this.submissionsAuthored) {
      this.submissionsAuthored.splice(
        this.submissionsAuthored.findIndex(sub => sub.id === updatedSubmission.id),
        1, updatedSubmission
      );
    }
  }

  getEventReviews(): Observable<Review[]> {
    if (this.eventReviews) {
      return of(this.eventReviews);
    } else {
      return this.reviewsService.getReviewsAssignedByEventByID(this.event.id, this.user.id)
        .pipe(map(r => {
          this.eventReviews = r;

          return this.eventReviews;
        }));
    }
  }

  getAssignedToUser() {
    if (!this.assignedToUser) {
      this.loading.assignedToUser = true;
      this.subscriptions.push(this.getEventReviews().subscribe(reviews => {
        this.assignedToUser = reviews
          .filter(r => r.user instanceof User ?
            r.user.id === this.user.id :
            r.user === this.user.id
          )
          .map(e => {
            e.submission.track = this.event.tracks.find(t => e.submission.trackID === t.id);
            return e;
          });

        this.loading.assignedToUser = false;
        this.toggleButtons.assignedToUser = BUTTONS.up;
      }));
    } else {
      this.assignedToUser = undefined;
      this.toggleButtons.assignedToUser = BUTTONS.down;
    }
  }

  getAssignedToOthers() {
    if (!this.assignedToOthers) {
      this.loading.assignedToOthers = true;

      this.subscriptions.push(this.getEventReviews().subscribe(reviews => {
        this.assignedToOthers = reviews
          .filter(r => (r.assignedBy instanceof User ?
            r.assignedBy.id === this.user.id :
            r.assignedBy === this.user.id) && !(r.user instanceof User ?
            r.user.id === this.user.id :
            r.user === this.user.id)
          )
          .map(e => {
            e.submission.track = this.event.tracks.find(t => e.submission.trackID === t.id);
            return e;
          });

        this.loading.assignedToOthers = false;
        this.toggleButtons.assignedToOthers = BUTTONS.up;
      }));
    } else {
      this.assignedToOthers = undefined;
      this.toggleButtons.assignedToOthers = BUTTONS.down;
    }
  }

  getRelationToEvents() {
    if (!this.relatedToEvent) {
      this.relatedToEvent = [];
      this.loading.relatedToEvent = true;
      this.loading.relatedToEventIndex = 0;

      this.eventsService.getEventByUserRole(this.user.id, UserEventRole.CHAIR, SHOW_ALL_PAGINATION_OPTIONS)
        .pipe(map(e => e.items))
        .subscribe(events => this.setRolesInEventRoles(events, UserEventRole.CHAIR));

      this.eventsService.getEventByUserRole(this.user.id, UserEventRole.COMMITTEE, SHOW_ALL_PAGINATION_OPTIONS)
        .pipe(map(e => e.items))
        .subscribe(events => this.setRolesInEventRoles(events, UserEventRole.COMMITTEE));

      this.eventsService.getEventByUserRole(this.user.id, UserEventRole.PUBLICATION_CHAIR, SHOW_ALL_PAGINATION_OPTIONS)
        .pipe(map(e => e.items))
        .subscribe(events => this.setRolesInEventRoles(events, UserEventRole.PUBLICATION_CHAIR));

      this.reviewsService.getUserReviews(SHOW_ALL_PAGINATION_OPTIONS, new SubmissionFilter(), this.user.id)
        .pipe(map(e => e.items))
        .subscribe(reviews => {
          const trackList = [...new Set(reviews.map(r => r.submission.trackID))];

          if (trackList.length === 0) {
            // User has no reviews in any event.
            this.setRolesInEventRoles([], UserEventRole.REVIEWER);
          } else {
            zip(...trackList.map(track => this.eventsService.getEventByTrack(track)))
              .subscribe(events => this.setRolesInEventRoles(events, UserEventRole.REVIEWER));
          }

        });
    } else {
      this.relatedToEvent = undefined;
      this.toggleButtons.relatedToEvent = BUTTONS.down;
    }
  }

  setRolesInEventRoles(events: Event[], role: UserEventRole) {
    events.forEach(event => {
      let found = this.relatedToEvent.find(e => e.event.id === event.id);
      if (!found) {
        found = new EventRole(event);
        this.relatedToEvent.push(found);
      }

      found.setRole(role);
    });

    this.loading.relatedToEventIndex++;
    if (this.loading.relatedToEventIndex > 3) {
      this.loading.relatedToEvent = false;
      this.toggleButtons.relatedToEvent = BUTTONS.up;
    }
  }

  getStatusClass(submission): String {
    let statusClass:String;
    statusClass = submission.status.toLowerCase();
    return statusClass;
  }
}
