import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { Mailto, NgxMailtoService } from 'ngx-mailto';
import { Review } from '../../model/paper';
import { Submission, SubmissionStatus } from '../../model/paper';
import { Track } from '../../model/track';
import { ReviewStatus } from 'src/app/enum/review.status';
import { AuthService } from '../../service/auth.service';
import { InfoService } from '../../service/info.service';
import { SelectOption } from '../../model/select.option';
import { NavbarService } from 'src/app/service/navbar.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { FilesService } from '../../service/files.service';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { ReviewsConfigurationService } from 'src/app/service/reviews.configuration.service';
import { EventReviewConfiguration, ViewersWithoutOwnValues } from 'src/app/model/eventReviewConfiguration';
import { ReviewsService } from 'src/app/service/reviews.service';
import { SubmissionWithdrawalComponent } from '../submission-withdrawal/submission-withdrawal.component';
import { SubmissionDeleteComponent } from '../submission-delete/submission-delete.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Event } from 'src/app/model/event';
import { CheckListField, CheckListRole } from 'src/app/model/checklist-field';
import { PaperGroup } from 'src/app/model/paper.group';
import { SubmissionStatusStyles } from 'src/app/enum/submission.status';
import { Subscription } from 'rxjs';
import { AdminService } from 'src/app/service/admin.service';
import { ExportService } from 'src/app/service/export.service';
import { PaperReviewHistoryComponent } from '../paper-review-history/paper-review-history.component';
import { SubmissionHistoryComponent } from '../submission-history/submission-history.component';
import { PrintingProblemComponent } from '../printing-problem/printing-problem.component';
import { PrintingProblemsComponent } from '../printing-problems/printing-problems.component';
import { SubmissionCoverage } from 'src/app/service/publication.service';
import { File } from 'src/app/model/file';
import { ChangeTracksDialogComponent } from '../change-tracks-dialog/change-tracks-dialog.component';

export type PaperInfoViewMode = 'full' | 'compact' | 'show-all-submissions';

const INTERVAL = {
  TIME: 250,
  MAX_ATTEMPTS: 10
};

export const UPDATE_FILE_TYPE = {
  UPLOAD: 'upload',
  DELETE: 'delete'
}

export type TrackFileLoading = {id: number, name: string, loading: boolean, statusUpload: number, onOver: boolean};

export interface ChangeTrackData {
  submissionID: number;
  sourceTrack: {id: number, name: string};
  targetTrack: {id: number, name: string};
  tracks: any[];
  oldEvent: number;
  newEvent: number;
  tracksWithFile: string[];
};

@Component({
  selector: 'app-paper-info',
  templateUrl: './paper-info.component.html',
  styleUrls: ['./paper-info.component.scss'],
  preserveWhitespaces: false
})
export class PaperInfoComponent implements OnInit {
  @Input() public element: Review | Submission;

  @Input() public viewMode: PaperInfoViewMode = 'full';

  public form: FormGroup;
  public status: Array<SelectOption>;

  submissionTrackForm: FormGroup;
  eventTrackList: Array<SelectOption>;

  @Input() paperGroupList: Array<SelectOption>;
  @Input() nameFormGroup: string;
  @Input() paperGroupForm: FormGroup;

  public TPCGroupList: Array<SelectOption>;

  @Input() userIsChair: boolean;
  public userIsSessionChair = false;
  public userCanEditStatus: boolean;
  public userCanAssignReviewers = false;
  public userIsReviewer = false;
  public showIeeeCopyright = false;
  public send_email: string;

  public submissionCovers: SubmissionCoverage[] = [];

  show = false;

  public reviewConfig: EventReviewConfiguration;
  public eventTimeZone: string;

  checklistForm: FormArray;
  checklistFields: Array<CheckListField>;
  checkListRoles: Array<CheckListRole> = [];

  iconCollection: { [key: string]: { fontSet: string, fontIcon: string, color: string } } = {};

  subscriptions: Subscription[] = [];
  public downloadLink = false;

  SubmissionStatus = SubmissionStatus;

  private updateFileType: string;
  public handlerTrackFiles: Array<TrackFileLoading>;

  constructor(
    private notificationService: NotificationService,
    private submissionsService: SubmissionsService,
    public filesService: FilesService,
    public reviewConfigurationService: ReviewsConfigurationService,
    private reviewsService: ReviewsService,
    private eventsService: EventsService,
    private navbarService: NavbarService,
    public authService: AuthService,
    private infoService: InfoService,
    private adminService: AdminService,
    private exportService: ExportService,
    private fb: FormBuilder,
    private mailtoService: NgxMailtoService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.iconCollection = SubmissionStatusStyles;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      status: [this.element.status],
    });

    if (this.isSubmission) {
      this.generateLoadingTrackFile(this.element as Submission);
    }

    const event = this.isReview ? (<Review>this.element).submission.event.id : (<Submission>this.element).event.id;
    this.status = (this.isReview ? ReviewStatus : SubmissionStatus).toSelectable();

    this.submissionsService.getPublicationInfo(this.submissionId).subscribe(covers => {
      this.submissionCovers = covers;
    });

    this.reviewConfigurationService.getReviewsConfiguration(event).subscribe(reviewConfig => {
      this.reviewConfig = reviewConfig;

      setTimeout(() => {
        this.verifyAndUpdateStatusPending();
        this.setPermissions();

        if (this.isSubmission && !this.submission.formSubmission) {
          this.getSubmissionAndForm();
        }
      });
    });
    this.eventTimeZone = this.submission.event.eventInfo.timeZone;
  }

  openInformationDialog(type: string) {
    const id = this.isReview ? (<Review>this.element).id : (<Submission>this.element).id;
    const event = this.isReview ? (<Review>this.element).submission.event.id : (<Submission>this.element).event.id;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '55rem';
    dialogConfig.height = '30rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';

    if (type === 'ReviewHistory' || type === 'PrintingProblems') {
      dialogConfig.data = { id, isSubmissionView: this.isSubmission };
    }

    if (type === 'ReviewHistory') {
      this.dialog.open(PaperReviewHistoryComponent, dialogConfig);
    } else if (type === 'SubmissionHistory') {
      dialogConfig.data = { id, userIsChair: this.userIsChair, eventId: event};
      this.dialog.open(SubmissionHistoryComponent, dialogConfig);
    } else if (type === 'PrintingProblems') {
      this.dialog.open(PrintingProblemsComponent, dialogConfig);
    }
  }

  getSubmissionAndForm() {
    this.submissionsService.getSubmission(this.submission.id, true).subscribe(submission => {
      this.generateLoadingTrackFile(submission);
      if (submission.event instanceof Event) {
        this.updateSubmission(submission);
      } else {
        const track_id = submission.track instanceof Track ? submission.track.id : submission.track;

        this.eventsService.getEventByTrack(track_id).subscribe(event_complete => {

          submission.event = event_complete;
          submission.track = event_complete.tracks.find(track => submission.trackID === track.id);
          this.updateSubmission(submission);
        });
      }
    });
  }

  private generateLoadingTrackFile(submission: Submission): void {
    const trackFiles = (<Track>submission.track).trackFiles;
    this.handlerTrackFiles = trackFiles.map(track => {
      return {
        id: track.id,
        name: track.name,
        loading: false,
        statusUpload: 0,
        onOver: false
      };
    });
  }

  public verifyAndUpdateStatusPending() {
    if (this.element.status === SubmissionStatus.PENDING && this.filesService.requiredFilesSubmitted((<Track>this.submission.track)?.trackFiles, this.submission.files.map(f => f.trackFile))) {
      this.form.get('status').setValue(SubmissionStatus.ACTIVE);
      this.submitStatusChange();
    }
  }

  public getUpdateFileType($event: string) {
    this.updateFileType = $event;
  }

  public updateSubmission(submission: Submission): void {
    if (this.isSubmission) {
      if (!this.updateFileType) {
        this.element = submission;
        return;
      }

      let attempt = 0;
      const previousFiles = (<Submission>this.element).files;

      // below function is necessary because the request with the new files uploaded is not immediate
      const getSubmissionInterval = setInterval(() => {
        this.submissionsService.getSubmission(submission.id, true)
          .subscribe((sub$: Submission) => {
            if (this.updateFileType === UPDATE_FILE_TYPE.DELETE) {
              // action: delete a file
              if (sub$.files.length != previousFiles.length) {
                updateFiles(submission, sub$.files);
                clearInterval(getSubmissionInterval);
              }
            }

            if (this.updateFileType === UPDATE_FILE_TYPE.UPLOAD) {
              // action: upload a file when no file is uploaded
              if (sub$.files.length > previousFiles.length) {
                updateFiles(submission, sub$.files);
                clearInterval(getSubmissionInterval);
              }

              // action: upload a file when a file is already uploaded
              const arr = buildArrayWithPreviousAndNewFiles(previousFiles, sub$.files);
              if (arr.length > previousFiles.length) {
                updateFiles(submission, sub$.files);
                clearInterval(getSubmissionInterval);
              }
            }

            if (attempt++ >= INTERVAL.MAX_ATTEMPTS) {
              clearInterval(getSubmissionInterval);
            }
          });
      }, INTERVAL.TIME);

    } else {
      (<Review>this.element).submission = submission;
    }
    if (this.userCanEditStatus && !this.isReview) {
      this.form.controls['status'].setValue(this.element.status);
    }

    const updateFiles = (submission: Submission, files: Array<File>) => {
      submission.files = files;
      this.element = submission;
      stopLoading();
    }

    // return an array with the IDs of the previous files and the new files without duplicates
    const buildArrayWithPreviousAndNewFiles = (previousFiles: Array<File>, newFiles: Array<File>) => {
      const IDsPreviousFiles = previousFiles.map(file => file.id);
      const IDsNewFiles = newFiles.map(file => file.id);

      return [... new Set([...IDsNewFiles, ...IDsPreviousFiles])];
    }

    const stopLoading = () => {
      this.handlerTrackFiles.forEach(trackFile => {
        trackFile.loading = false;
        trackFile.statusUpload = 0;
      });
    }
  }

  public updateSubmissionValue(field: string, value: any) {
    if (this.isSubmission) {
      this.element[field] = value;
    } else {
      (<Review>this.element).submission[field] = value;
    }
  }

  public get isReview(): boolean {
    return this.element instanceof Review;
  }

  public get isSubmission(): boolean {
    return this.element instanceof Submission;
  }

  public get review(): Review {
    return this.isReview ? this.element as Review : undefined;
  }

  public get submission(): Submission {
    return this.isReview ? (this.element as Review).submission : this.element as Submission;
  }

  public get isAuthor(): boolean {
    return this.submission.authors.some(author => author.user.id === this.infoService.user.id);
  }

  public get submissionId(): number {
    return this.isReview ? (<Review>this.element).submission.id : (<Submission>this.element).id;
  }

  public get submissionPublicationIsReady(): boolean {
    return this.submissionCovers.filter(c => !c.answer).length == 0;
  }

  public getPaperGroup(): string {
    const paperGroup : number | PaperGroup | null = this.submission.paperGroup;
    if(paperGroup && this.paperGroupList){
      if(typeof paperGroup === "number"){
        for(let paperGroupOption of this.paperGroupList){
          if(paperGroupOption.id == paperGroup){
            return paperGroupOption.value;
          }
        }
      } else {
        for(let paperGroupOption of this.paperGroupList){
          if(paperGroupOption.id == paperGroup.id){
            return paperGroupOption.value;
          }
        }
      }
    }
    return "";
  }

  private getTPCGroups(): void {
    const details = "minimum";
    this.eventsService.getEventTPCGroups(this.submission.event.id, details)
      .subscribe(TPCgroups => {
        this.TPCGroupList = TPCgroups.map(TPCgroup => new SelectOption(TPCgroup.id, TPCgroup.name));
      })
  }

  public sendEmail(): void {
    let submission = this.submission;
    var usersEmails = this.submission.authors.map(author => {
        let affiliation = ''
        if (author.user.profile.affiliation?.acronym) {
          affiliation = `(${author.user.profile.affiliation.acronym}) `
        }
        return `${author.user.name} ${affiliation}<${author.user.email}>`;
    });

    var recepient = usersEmails[0];
    var cc = usersEmails.slice(1).toString();
    var event = this.submission.event;
    var event_acronym = `${ this.submission.event.year ? this.submission.event.acronym + " " +  this.submission.event.year.toString() : this.submission.event.acronym }`;
    var body = `Dear ${this.submission.authorNames},\n\nNotification for your paper #${submission.id} (${submission.title}) for ${event.name} (${event.acronym} ${event.year}).\n\n`;

    var mailto: Mailto = {
      receiver: recepient,
      cc: cc,
      subject: `[${event_acronym}] Notification for your paper #${submission.id} (${submission.title})`,
      body: body
    };

    this.mailtoService.open(mailto);
  }

  private setPermissions(): void {
    if (this.userIsChair === undefined){
      this.authService.userIsAdminOrChair(this.isReview ?
        (<Review>this.element).submission.event.id :
        (<Submission>this.element).event.id
      ).subscribe(isAdminOrChair => {
        // If it is a chair user, allows its permissions only if its on the admin page
        this.userIsChair = isAdminOrChair;
        this.setUserCanAssignReviewers();
        this.setUserCanEditStatus();
        this.setEventTracks();
        this.setShowIeeeCopyright();

        if (!this.paperGroupForm) {
          this.setPaperGroup();
        }

        // this.getCheckListAnswers();
      });
    } else {
      this.setUserCanAssignReviewers();
      this.setUserCanEditStatus();
      this.setEventTracks();
      this.setShowIeeeCopyright();

      if (!this.paperGroupForm) {
        this.setPaperGroup();
      }
    }

    if (this.submission.isSessionChair(this.infoService.user.id)){
      this.userIsSessionChair = true;
    }
  }

  public setShowIeeeCopyright(): void {
    if (this.isSubmission && this.submission.status === SubmissionStatus.ACCEPTED && this.submission.event.ieeeCopyrightEnable && (this.userIsChair || this.isAuthor)) {
      this.showIeeeCopyright = true;
    } else {
      this.showIeeeCopyright = false;
    }
  }

  public setUserCanAssignReviewers(): void {
    this.userCanAssignReviewers = this.userIsChair;
    if (this.isReview) {
      this.userIsReviewer = this.review.userId === this.infoService.user.id;
    }
  }

  public setUserCanEditStatus(): void {
    this.userCanEditStatus = this.userIsChair;
  }

  public setPaperGroup(): void {
    if (this.viewMode == 'full' && !this.userIsChair) {
      return;
    }

    if (!this.isReview) {
      this.getTPCGroups();
      this.eventsService.getEventPaperGroups(this.submission.event.id).subscribe(groups => {
        this.paperGroupList = groups.map(group => new SelectOption(group.id, group.name));

        groups.some(group => {
          const submission = group.submissions.find(s => s.id === this.submissionId);

          if (submission) {
            this.submission.paperGroup = group.id;
          }

          return !!submission;
        });

        this.paperGroupForm = this.fb.group({
          paperGroup: [{ value: this.submission.paperGroup, disabled: this.paperGroupList.length === 0 }],
          tpcGroup: [this.submission.tpcGroup]
        });
      });
    }
  }

  public setEventTracks() {
    if (!this.isReview && this.viewMode == 'full') {
        this.eventsService.getAllSubEventsTracks(this.submission.event?.id).subscribe(tracks => {
          this.submission.event.tracks = [...this.submission.event.tracks, ...tracks];
      }, () => {},
      () => {
        this.eventTrackList = this.submission.event.tracks.map(track => new SelectOption(track.id, track.name));

        this.submissionTrackForm = this.fb.group({
          track: [{ value: this.submission.trackID, disabled: this.eventTrackList?.length === 1 }]
        });
      });
    }
  }

  submitStatusChange() {
    if (this.form.valid) {
      const { status } = this.form.value;

      if (this.element instanceof Submission) {
        if (status === SubmissionStatus.WITHDRAWN) {
          this.openWithdrawal();
        } else {
          this.navbarService.loadingStart();

          this.submissionsService.editSubmission({ status }, this.element.id).subscribe(updatedSubmission => {
            // keys only 'this.element' has:
            //    "noShow", "submittedAt", "statusChangedAt", "printingProblems", "files", "form", "event", "reviews"
            // keys only 'updatedSubmission'
            //    has: "paperGroup", "formAnswer"
            // this is necessary to trigger changes, that only notice if the references change.

            this.refreshSubmission(updatedSubmission);
            this.notificationService.notify('submissions.edit.status', { params: { id: this.element.id } });
            this.navbarService.loadingStop();
          });
        }
      }
    }
  }

  submitTrackChange() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '365px';
    let oldEvent = this.submission.event.id;
    
    (dialogConfig.data as ChangeTrackData) = {
      submissionID: this.submission.id,
      sourceTrack: {
        id: this.submission.trackID,
        name: this.submission.event.acronym + ' ' +  this.submission.event.year + ' - ' + (this.submission.track as Track).name
      },
      targetTrack: {
        id: this.submissionTrackForm.value.track,
        name: this.eventTrackList.find(track => track.id === this.submissionTrackForm.value.track).value
      },
      tracks: this.submission.event.tracks,
      oldEvent: this.submission.event.id,
      newEvent: this.submission.event.tracks.find(track => track.id === this.submissionTrackForm.value.track).event,
      tracksWithFile: this.submission.files.map(file => file.trackFile.name),
    };

    const dialogRef = this.dialog.open(ChangeTracksDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(submission => {
      let newTrack = submission?.track;
      if (newTrack && this.submission.event.tracks.find(track => track.id === newTrack).event != oldEvent) {
        this.router.navigate(['/submissions/', this.submission.id]);
      }
      this.refreshSubmission(submission);
      this.setEventTracks();
    });
  }

  ieeeElectronicCopyrightComponent(): void {
    this.router.navigate(['/submissions/', this.submission.id, 'ieeeElectronicCopyright']);
  }

  reviewsUpdated(reviews: Review[]) {
    if (this.isSubmission) {
      // TODO Bugfix: class-transformer can't handle transforming dayjs dates,
      // so class-transformer fails on creating a new valid Submission instance.
      // When that is fixed, the following comment updates the review list without an API call.

      // const element = (<Submission> this.element);
      // element.reviews = reviews;
      // (<Submission>this.element) = classToClass(element);

      // WORKAROUND: Refresh submission via API
      this.submissionsService.getSubmission(this.element.id, true).subscribe(s => {
        this.eventsService
          .getEventByTrack(s.trackID)
          .subscribe(event => {
            s.event = event;
            s.track = event.tracks.find(track => s.trackID === track.id);

            this.element = s;
          });
        });
    } else {
      if (this.userIsChair || this.reviewConfig.tpcCanView === ViewersWithoutOwnValues.ASSIGNER) {
        // WORKAROUND: Refresh review via API, same as above
        this.reviewsService.getReview(this.review.id, true).subscribe(r => {
          this.eventsService
            .getEventByTrack(this.submission.trackID)
            .subscribe(event => {
              r.submission.event = event;
              r.submission.track = event.tracks.find(track => this.submission.trackID === track.id);

              this.element = r;
            });
          });
      } else {
        this.router.navigate(['/reviews']);
      }
    }
  }

  private getCheckListAnswers(updateRoles = true): void {
    this.eventsService.getSubmissionCheckListAnswers(this.submission.id).subscribe(fields => {
      this.checklistFields = fields;

      if (updateRoles) {
        // If checklist defined, obtain user checklist roles
        if (this.checklistFields?.length > 0) {
          if (this.userIsChair) {
            this.checkListRoles.push(CheckListRole.chair);
          }
          if (this.element instanceof Submission) {
            if (this.submission.isAuthor(this.infoService.user.id)) {
              this.checkListRoles.push(CheckListRole.author);
            }

            if (this.submission.isReviewer(this.infoService.user.id)) {
              this.checkListRoles.push(CheckListRole.reviewer);
            }
          }
        // TODO: Publication Chair
        // TODO: Session Chair
        }
      }

      this.checklistForm = this.fb.array(this.checklistFields.map(field => this.fb.group({
          id: [field.answer?.id],
          value: [{ value: field.answer?.value, disabled: !field.userModifiable(this.checkListRoles) }],
          checklist_field: [field.id],
          description: [field.description]
        })
      ));
    });
  }

  setChecklistFieldValue(field) {
    const { id } = field.value;

    const obs = id ?
      this.eventsService.editSubmissionCheckListAnswers([field.value], this.submission.id) :
      this.eventsService.createSubmissionCheckListAnswers([field.value], this.submission.id);

    obs.subscribe(() => {
      this.notificationService.notify('submissions.view.checklist-updated', { params: { id: this.submission.id } });
      this.getCheckListAnswers(false);
    });
  }

  openWithdrawal(): void {
    if(this.submission.withdrawable){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = false;
      dialogConfig.data = { submission: this.submission, submissions: [] }
  
      let dialogRef = this.dialog.open(SubmissionWithdrawalComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe((withdrawedSubmission?: Submission) => {
        if (withdrawedSubmission) {
          this.updateSubmissionValue('status', withdrawedSubmission.status);
          this.refreshSubmission(withdrawedSubmission);
        }
      }, () => {}, () => {
        if (this.userCanEditStatus && !this.isReview) {
          this.form.controls['status'].setValue(this.submission.status);
        }
      });
    }else{
      this.notificationService.notifyError('forms.fields.withdraw-unavailable');
    }
  }

  openDelete(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = false;
    dialogConfig.data = { submission: this.submission, submissions: [] }

    let dialogRef = this.dialog.open(SubmissionDeleteComponent, dialogConfig);
  }

  refreshSubmission(extraSource?: Submission) {
    // Copy original submission element into a new variable, to trigger ngOnChanges on child components.
    const submission = new Submission();
    Object.assign(submission, this.submission, extraSource);

    if (this.isSubmission) {
      if (!(submission.event instanceof Event)) {
        submission.event = this.submission.event;
      }

      if (!(submission.track instanceof Track)) {
        submission.track = submission.event.tracks.find(t => t.id === submission.trackID);
      }

      this.element = submission;
      this.generateLoadingTrackFile(this.element as Submission);
      this.setShowIeeeCopyright();
    } else {
      this.eventsService
        .getEventByTrack(this.submission.trackID)
        .subscribe(event => {
          submission.event = event;
          submission.track = event.tracks.find(track => this.submission.trackID === track.id);

          (<Review>this.element).submission = submission;
        });
    }
  }

  refreshReviewRequested(event: { reviewId: number, delete?: boolean }) {
    if (event.delete) {
      if (this.element instanceof Submission) {
        this.element.reviews.splice(this.element.findReviewIndex(event.reviewId), 1);
        this.refreshSubmission();
      } else {
        // Do nothing, because paper-info Review page will break.
        // this.element = undefined;
      }
    } else {
      this.reviewsService.getReview(event.reviewId, true).subscribe(refreshedReview => {
        if (this.element instanceof Submission) {
          const reviewIndex = this.element.reviews.findIndex(r => {
            if (r instanceof Review) {
              return r.id === event.reviewId;
            }
            return r === event.reviewId;
          });

          this.element.reviews.splice(reviewIndex, 1, refreshedReview);
          this.refreshSubmission();
        } else {
          this.element = refreshedReview;
          this.submission.reviews = [this.element];
          this.refreshSubmission();
        }
      });
    }
  }

  generateExportHTML() {
    this.adminService.progressBar.start();

    const oldViewMode = this.viewMode;
    this.viewMode = 'show-all-submissions';

    setTimeout(() => {
      this.subscriptions.push(this.exportService.extractExportHTML('showAll').subscribe(
        ({content, styles, links}) => {
          const html = `
            <!doctype html>
            <html>
              <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">

                <title>${this.adminService.selectedEvent.name}</title>
                <style>
                  .action-buttons .export { display: none; }
                  .main { margin: 1rem; }
                  .name { display: flex; }
                </style>
                ${styles}
                ${links}
              </head>
              <body>
                <div class="main paper-info">
                  ${content}
                </div>
              </body>
            </html>
          `;
          this.exportService.setDownloadTarget('saveHTML', `${this.adminService.selectedEvent.name}.html`, html, 'text/html');
          this.viewMode = oldViewMode;
          this.adminService.progressBar.stop();
          this.downloadLink = true;
      }));
    }, 1000);
  }

}
