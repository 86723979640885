import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SelectOption } from '../../../../model/select.option';
import { EventsService } from 'src/app/service/events.service';
import { AdminService } from '../../../../service/admin.service';
import { NotificationService } from 'src/app/service/notification.service';
import { UserEvent } from '../../../../model/user.event';
import { Event } from '../../../../model/event';
import { NotificationEmail } from 'src/app/model/notificationEmail';
import { User } from 'src/app/model/user';
import { EmailStatus } from 'src/app/enum/emails.status';

const DefaultAll = 'all';

@Component({
  selector: 'app-event-send-email',
  templateUrl: './event-send-email.component.html',
  styleUrls: ['./event-send-email.component.scss']
})
export class EventSendEmailComponent implements OnInit {
  public form: FormGroup;
  public formReviewers: FormGroup;
  public formAuthors: FormGroup;
  public formCopyTo: FormGroup;

  public members: Array<UserEvent>;
  public event: Event;
  public preview: Array<NotificationEmail> = [];

  public loadingPreview = false;
  loadingRecipients: boolean = false;

  public tracks: Array<SelectOption> = [
    {
      id: DefaultAll,
      value: 'admin.event.people.send-email.all-tracks'
    }
  ];


  public groups: Array<SelectOption> = [
    {
      id: DefaultAll,
      value: 'admin.event.people.send-email.all-groups'
    }
  ];

  public tpcGroups: Array<SelectOption> = [
    {
      id: DefaultAll,
      value: 'admin.event.people.send-email.all-groups'
    }
  ];

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private eventsService: EventsService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initForm();
        });
      } else {
        this.initForm();
      }
    });
  }

  initForm(): void {
    this.setSelectsOption();

    this.form = this.fb.group({
      subject: ['[{{event.acronym}} {{event.year}}] - ', [Validators.required]],
      content: ['', [Validators.required]]
    });

    this.formAuthors = this.fb.group({
      track: [DefaultAll],
      paper_group: [DefaultAll],
      paper_status: this.fb.group({
        accepted: [false],
        rejected: [false],
        pending: [false],
        active: [false]
      }),
    });

    this.formReviewers = this.fb.group({
      track: [DefaultAll],
      tpc_group: [DefaultAll],
      paper_group: [DefaultAll],
      reviewers_filter: this.fb.group({
        all_tpc: [false],
        tpc_reviewing_papers: [false],
        all_reviewers_conference: [false],
        all_reviewers_unfinished: [false],
        session_chair: [false]
      }),
    });

    this.formCopyTo = this.fb.group({
      myself: [false],
      chair: [false],
      users: [[]]
    });
  }

  setSelectsOption(): void {
    this.tracks.push(...this.event.tracks.map( t => ({
      id: t.id,
      value: t.name
    })));

    this.eventsService.getEventPaperGroups(this.event.id).subscribe( paperGroups => {
      this.groups.push(...paperGroups.map( group => ({
        id: group.id,
        value: group.name
      })));
    });

    this.eventsService.getEventTPCGroups(this.event.id).subscribe( tpcGroups => {
      this.tpcGroups.push(...tpcGroups.map( group => ({
        id: group.id,
        value: group.name
      })));
    });
  }

  getEmailParams() {
    const params = this.form.getRawValue();
    params['authors'] = {};
    params['reviewers'] = {};

    if (this.formAuthors.value.paper_group !== DefaultAll) {
      params.authors['paper_group'] = this.formAuthors.value.paper_group;
    }

    if (this.formAuthors.value.track !== DefaultAll) {
      params.authors['track'] = this.formAuthors.value.track;
    }

    const paper_status = Object.keys(this.formAuthors.value.paper_status).filter(key => this.formAuthors.value.paper_status[key]);
    if (paper_status.length) {
      params.authors['paper_status'] = paper_status;
    }

    Object.keys(this.formReviewers.value).forEach(key => {
      if (this.formReviewers.value[key] !== DefaultAll) {
        params.reviewers[key] = this.formReviewers.value[key];
      }
    });

    const reviewersFilterControl = this.formReviewers.value.reviewers_filter;
    const reviewersFilters = Object.keys(reviewersFilterControl).filter(key => reviewersFilterControl[key]);
    params.reviewers['reviewers_filter'] = reviewersFilters;

    params['copy_to'] = this.formCopyTo.getRawValue();
    return params;
  }

  submit(): void {
    const params = this.getEmailParams();
    this.eventsService.sendPersonalizedEmail(this.event.id, params).subscribe(
      r => {
      this.notificationService.notify('admin.event.people.send-email.send-succeeded');
      },
      error => {
      this.notificationService.notifyError('admin.event.people.send-email.send-failed');
    });
  }

  getPreview(): void {
    const params = this.getEmailParams();
    this.loadingPreview = true;
    this.eventsService.showPreviewPersonalizedEmail(this.event.id, params).subscribe(preview => {
      this.loadingPreview = false;
      this.preview = preview;
    });
  }

  private downloadLink(downloadURL: string, name: string) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
    this.loadingRecipients = false;
  }

  public generateEmailsExport() {   
    this.loadingRecipients = true;
    const params = this.getEmailParams();
    this.eventsService.showPreviewPersonalizedEmail(this.event.id, params).subscribe(preview => {
      if (preview && preview.length > 0){
        setTimeout(() => {
          let emailList = [];
          emailList = preview.map(m => [...m.to, ...m.cc]);
          emailList = emailList.flat().filter((value, index, self) => self.indexOf(value) === index);
          
          const emailsList = emailList.map(email => `${email},`).join('\n').slice(0,-1);
          setTimeout(() => {
            const blob = new Blob([emailsList], {type: 'text/plain'});
            const downloadURL = URL.createObjectURL(blob);
            this.downloadLink(downloadURL, `emails_list.txt`);
          }, 1000);
        }, 1000);
      }
    });
  }
}
