import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl, ValidatorFn } from '@angular/forms';
import { SystemService } from 'src/app/service/system.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { AdminService } from 'src/app/service/admin.service';
import { SelectOption } from 'src/app/model/select.option';
import { EventTitlePipe } from 'src/app/pipe/event.title.pipe';
import { EventsService } from 'src/app/service/events.service';
import { EventType } from 'src/app/model/eventType';
import { NotificationService } from 'src/app/service/notification.service';
import { Event } from 'src/app/model/event';
import { Country } from 'src/app/model/country';
import { TimeZone } from 'src/app/model/timeZone';
import { EventInfo } from 'src/app/model/eventInfo';
import { TimezoneService } from 'src/app/service/timezone.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-event-settings',
  templateUrl: './event-settings.component.html',
  styleUrls: ['./event-settings.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class EventSettingsComponent implements OnInit {
  event: Event;
  subEvents: Array<Event>;
  subEvents$: Observable<any>;
  eventForm: FormGroup;
  eventTypes: EventType[];
  eventInfoForm: FormGroup;
  countries: Country[];
  timeZones: TimeZone[];
  eventInfo: EventInfo;
  eventCountryOption: Country;
  eventCountryList: Array<Country>;
  parentOption: SelectOption;
  parentList: Array<SelectOption> = [];
  enabledDisabledOption: SelectOption;
  enabledDisabledOptions: Array<SelectOption>;
  enabledIEEECF = false;

  allCities: any;

  events: Array<SelectOption>;
  /* eslint-disable constructor-super */
  /* @ts-ignore */
  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    public systemService: SystemService,
    public eventsService: EventsService,
    public notificationService: NotificationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private timezoneService: TimezoneService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.allCities = this.systemService.AllCities;
      this.timeZones = this.timezoneService.getAllTimeZones();

      this.systemService.countries.subscribe(countries => {
        this.countries = countries;
      });

      this.eventsService.getEventTypes().subscribe(types => {
        this.eventTypes = types;
      });

      this.adminService.getEventSettings().subscribe(event => {
        next: {
          this.event = event;
          this.initEventForm();
          this.initEventInfoForm();
  
          if(this.event.eventParent instanceof Event){
            this.parentOption = new SelectOption(this.event.eventParent.id, `${this.event.eventParent.acronym} - ${this.event.eventParent.name}`);
            this.parentList = new Array;
            this.parentList.push(this.parentOption);
          }
          this.eventCountryOption = new Country;
          this.eventCountryOption.isoCode = this.event.eventInfo.country;
          this.eventCountryOption.name = this.event.eventInfo.country;
          this.eventCountryList = new Array;
          this.eventCountryList.push(this.eventCountryOption);
  
          this.enabledDisabledOptions = new Array;
          this.enabledDisabledOption = new SelectOption(false, "false");
          this.enabledDisabledOptions.push(this.enabledDisabledOption);
          this.enabledDisabledOption = new SelectOption(true, "true");
          this.enabledDisabledOptions.push(this.enabledDisabledOption);
        }
        complete: {
          this.getSubEvents();
        }
      });
    });
  }

  private getSubEvents(): void {
    this.subEvents$ = this.eventsService.getSubconferencesByEvent(this.event.id);
    this.subEvents$.subscribe(subEvents => {
      if (subEvents.length > 0) {
        this.subEvents = subEvents;
      }
    });
  }

  private initEventForm(): void {
    this.eventForm = this.fb.group({
      name: [this.event.name, [Validators.required, Validators.minLength(1), Validators.maxLength(150)]],
      acronym: [this.event.acronym, [Validators.required, Validators.minLength(1), Validators.maxLength(30)]],
      uri: [this.event.uri, [Validators.required], [this.eventsService.eventURIValidator(this.event.uri)]],
      year: [this.event.year],
      eventType: [this.event.eventType.id, Validators.required],
      eventParent: [this.event.eventParent instanceof Event ? this.event.eventParent.id : this.event.eventParent],
      minTopics: [this.event.minTopics],
      maxTopics: [this.event.maxTopics],
      ieeeCopyrightEnable: [this.event.ieeeCopyrightEnable],
      ieeePubTitle: [this.event.ieeePubTitle],
      ieeeArtSource: [this.event.ieeeArtSource],
      isbn: [this.event.isbn],
      issn: [this.event.issn],
      ieeePartNumber: [this.event.ieeePartNumber]
    }, { updateOn: 'blur' });

    this.eventForm.get('acronym').valueChanges.subscribe(acronym => {
      const uri = this.eventForm.get('uri');
      uri.setValue(acronym.replace(/\s/g, '').toLowerCase());
    });

    this.eventForm.get('uri').valueChanges.subscribe(uri => {
      this.eventForm.get('uri').setValue(uri.replace(/\s/g, '').toLowerCase(), { emitEvent: false });
    });

    this.eventForm.get('ieeeCopyrightEnable').valueChanges.subscribe(ieeeCopyrightEnableForm => {
      this.enabledIEEECF = ieeeCopyrightEnableForm;
      this.changeDetectorRef.detectChanges();
    });

    this.enabledIEEECF = this.event.ieeeCopyrightEnable;
  }

  private initEventInfoForm(): void {
    this.eventInfoForm = this.fb.group({
      description: [this.event.eventInfo.description, [Validators.minLength(1), Validators.maxLength(2500)]],
      city: [this.event.eventInfo.city, [Validators.required]],
      country: [this.event.eventInfo.country, [Validators.required]],
      makePublicAt: [this.event.eventInfo.makePublicAt ?.toDate()],
      beginAt: [this.event.eventInfo.beginAt ?.toDate()],
      endAt: [this.event.eventInfo.endAt ?.toDate()],
      tpcInvitationDueAt: [this.event.eventInfo.tpcInvitationDueAt ?.toDate()],
      state: [this.event.eventInfo.state, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      timeZone: [this.event.eventInfo.timeZone, [Validators.required]]
    }, { updateOn: 'blur' });
  }

  get baseUrl(): string {
    return window.location.origin;
  }

  submitInfo () {
    setTimeout(() => {
    if (this.eventForm.valid && this.eventInfoForm.valid) {
      const event = new Event(this.eventForm.value.name, this.eventForm.value.acronym);
      event.uri = this.eventForm.value.uri;
      event.year = this.eventForm.value.year;
      event.eventType = this.eventForm.value.eventType;
      event.eventParent = this.eventForm.value.eventParent ? this.eventForm.value.eventParent : null;

      event.minTopics = this.eventForm.value.minTopics;
      event.maxTopics = this.eventForm.value.maxTopics;

      event.ieeeCopyrightEnable = this.eventForm.value.ieeeCopyrightEnable;
      event.ieeePubTitle = this.eventForm.value.ieeePubTitle;
      event.ieeeArtSource = this.eventForm.value.ieeeArtSource;
      event.isbn = this.eventForm.value.isbn;
      event.issn = this.eventForm.value.issn;
      event.ieeePartNumber = this.eventForm.value.ieeePartNumber;
      
      const eventInfo = new EventInfo();
      eventInfo.description = this.eventInfoForm.value.description;
      eventInfo.city = this.eventInfoForm.value.city;
      eventInfo.state = this.eventInfoForm.value.state;
      eventInfo.country = this.eventInfoForm.value.country;
      eventInfo.timeZone = this.eventInfoForm.value.timeZone;
      eventInfo.makePublicAt = this.eventInfoForm.value.makePublicAt;
      eventInfo.beginAt = this.eventInfoForm.value.beginAt;
      eventInfo.endAt = this.eventInfoForm.value.endAt;
      eventInfo.tpcInvitationDueAt = this.eventInfoForm.value.tpcInvitationDueAt;

      this.notificationService.notify('admin.event.settings.edit-event-info-start', {
        params: {
          id: this.event.id
        }
      });

      this.eventsService.editEvent(this.event.id, event).subscribe(e => {
        this.adminService.selectedEvent = e;
        this.event = e;
        this.eventForm.get('uri').clearAsyncValidators();
        this.eventForm.get('uri').setAsyncValidators(this.eventsService.eventURIValidator(this.event.uri));
        this.notificationService.notify('admin.event.settings.edit-event-info-success', {
          params: {
            id: this.event.id
          }
        });
      });

      this.eventsService.editEventInfo(this.event.eventInfo.id, eventInfo).subscribe(event => {
        this.event.eventInfo = event;
        this.adminService.selectedEvent = this.event;
        this.notificationService.notify('admin.event.settings.edit-event-info-success', {
          params: {
            id: this.event.id
          }
        });
      });

    } else {
      this.eventForm.markAllAsTouched();
      this.eventInfoForm.markAllAsTouched();
      this.notificationService.notifyError('admin.event.settings.edit-event-info-error', {
        params: {
          id: this.event.id
        }
      });
    }

    this.validateField(this.eventForm.value.name, 'admin.event.settings.error.name-required');
        this.validateField(this.eventForm.value.acronym, 'admin.event.settings.error.acronym-required');
        this.validateField(this.eventForm.value.eventType, 'admin.event.settings.error.eventType-required');
        this.validateField(this.eventForm.value.year, 'admin.event.settings.error.year-required');
        this.validateField(this.eventForm.value.uri, 'admin.event.settings.error.uri-required');
        this.validateField(this.eventInfoForm.value.timeZone, 'admin.event.settings.error.timeZone-required');
        this.validateField(this.eventInfoForm.value.city, 'admin.event.settings.error.city-required');
        this.validateField(this.eventInfoForm.value.country, 'admin.event.settings.error.country-required');

      
    }, 50);
  } 

  public validateField(fieldValue, errorMessage) {
    if (!fieldValue) {
      this.notificationService.notifyError(errorMessage, {
        params: {
          id: this.event.id
        }
      });
    }
  }

  public setState_Country_Timezone($event): void {
    const stateCode = $event.state;
    const countryCodeAlpha2 = $event.country;
    const countryCodeAlpha3 = this.systemService.transformAlpha2ToAlpha3(countryCodeAlpha2);
    const nameCity = $event.city;
    const nameState = this.systemService.getNameStateByCodeAndCountry(stateCode, countryCodeAlpha2);
    const nameCountry = this.systemService.getNameCountryByCode(countryCodeAlpha2);

    this.eventInfoForm.controls.state.setValue(stateCode);
    
    const API_Country = this.countries.filter(country => country.isoCode === countryCodeAlpha3);
    if (API_Country.length > 0) {
      this.eventCountryOption.isoCode = API_Country[0].isoCode;
      this.eventCountryOption.name = API_Country[0].name;
      this.eventCountryList.push(this.eventCountryOption);
      this.eventInfoForm.controls.country.setValue(this.eventCountryOption.isoCode)
    }

    if (!!nameState && !!nameCountry) {
      const timezone = this.timezoneService.getTimeZone(nameCity, nameState, nameCountry);
      this.eventInfoForm.controls.timeZone.setValue(timezone);
    }
  }

  public generateEventInfoExport() {    
    if (this.subEvents && this.subEvents.length > 0){
      setTimeout(() => {  
        let event = this.event;  
        let eventList = `id;acronym;year;name;jems_uri;parente_event\n`
        eventList += `${event.id};${event.acronym};${event.year};\"${event.name}\";jems3.sbc.org.br/${event.uri};${event.eventParent}\n`
        eventList += this.subEvents.map(e => `${e.id};${e.acronym};${e.year};\"${e.name}\";jems3.sbc.org.br/${e.uri};${e.eventParent}`).join('\n');
        setTimeout(() => {
          const blob = new Blob([eventList], {type: 'text/plain'});
          const downloadURL = URL.createObjectURL(blob);
          this.downloadLink(downloadURL, `events_list.csv`);
        }, 1000);
      }, 1000);
    }
  }

  private downloadLink(downloadURL: string, name: string) {
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }
}
