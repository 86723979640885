import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadViewDirective } from 'src/app/class/file-upload-view';
import { File } from 'src/app/model/file';
import { FileRules } from 'src/app/model/file.rules';
import { Submission } from 'src/app/model/paper';
import { Track } from 'src/app/model/track';
import { EventsService } from 'src/app/service/events.service';
import { FilesService } from 'src/app/service/files.service';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-file-rules-compact',
  templateUrl: './file-rules-compact.component.html',
  styleUrls: ['./file-rules-compact.component.scss']
})
export class FileRulesCompactComponent extends FileUploadViewDirective implements OnInit {
  @Input() submission: Submission;
  @Input() isReviewView: boolean;
  @Input() userIsChair: boolean;
  @Input() userIsSessionChair: boolean;

  @Output() updateSubmission = new EventEmitter<{ submission: Submission, setEventAndTrack?: boolean }>();
  @Output() uploadComplete = new EventEmitter <{ fileRule: FileRules, status: number }>();

  constructor(
    public fb: FormBuilder,
    public submissionsService: SubmissionsService,
    public eventsService: EventsService,
    public filesService: FilesService,
    public infoService: InfoService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {
    super(fb, submissionsService, filesService, eventsService, infoService);
  }

  ngOnInit(): void {
    this.initUploadForm();
  }

  fileUploaded(trackFile: FileRules): File {
    return this.submission.files.find(file => file.trackFile.id === trackFile.id);
  }

  fileUploadComplete(fileRule: FileRules, status: number): void {
    this.uploadComplete.emit({ fileRule, status });
  }

  deleteFile(file: File) {
    this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true, autoFocus: false,
      data: {
        title: 'messages.confirm-file-deletion',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.deleteSubmissionFile(file, this.submission).subscribe(() => {
          // TODO: Edit submission returns incomplete submission, so refreshing submission is necessary.
          // Extra getSubmission can be removed when editSubmission serializes the correct value.
          this.submissionsService.getSubmission(this.submission.id, true).subscribe(submission => {
            this.updateSubmission.emit({ submission, setEventAndTrack: true });
            this.notificationService.notify('messages.removed-file');
          });
        });
      }
    });
  }
}
