import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Event } from 'src/app/model/event';
import { ProceedingsContent, ProceedingsContentType } from 'src/app/model/proceedingsContent';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { PublicationService } from 'src/app/service/publication.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { FilesService } from 'src/app/service/files.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { File, FileUploadErrors } from 'src/app/model/file';
import { NotificationService } from 'src/app/service/notification.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Session } from 'src/app/model/session';
import { Subscription, forkJoin } from 'rxjs';
import { Submission } from 'src/app/model/paper';
import { Track } from 'src/app/model/track';
import * as _ from 'lodash';
import { FileRules } from 'src/app/model/file.rules';
import { TranslateService } from '@ngx-translate/core';
import { ProceedingsViewingOptions } from 'src/app/model/proceedingsViewingOptions';
import { SelectOption } from 'src/app/model/select.option';
import { ArticlesListCardDialogComponent } from '../articles-list-card-dialog/articles-list-card-dialog.component';

export class ProceedingsIndex {

  content: ProceedingsContent;
  title: string;
  pageStart: number;
  length: number;

  constructor(content: ProceedingsContent, title: string, pageStart?: number, length?: number) {
    this.content = content;
    this.title = title;
    this.pageStart = pageStart ? pageStart : -1;
    this.length = length ? length : 1;
  }

}

@Component({
  selector: 'app-proceeding-step',
  templateUrl: './proceeding-step.component.html',
  styleUrls: ['./proceeding-step.component.scss']
})
export class ProceedingStepComponent implements OnInit {

  @Input() step: string;
  @Input() steps: string[];
  @Input() event: Event;
  @Input() includedInContent: ProceedingsContent[];
  @Input() sessions: Session[];
  @Input() submissions: Submission[];
  @Input() proceedingsTrackFiles: FileRules[];
  @Input() indexesOptions: ProceedingsViewingOptions;

  loadedSections = false;
  fileUploadPercent: number;

  notIncludedInContent: ProceedingsContent[] = [];

  index: ProceedingsContent;
  authorIndex: ProceedingsContent;
  indexList: ProceedingsIndex[] = [];

  tracks: Track[];

  identificationForm: FormGroup;
  trackFileForms: FormArray;
  tracksLists = {};
  indexesForm: FormGroup;

  sectionList: Object[];

  public loadingLink = false;

  public link;
  public articlesNotUsingSbcTemplate;
  public solSeriesValues: Array<SelectOption> = [];
  public seriesForm: FormGroup;
  public sectionForms: FormArray;
  public solSeriesSelected = false;
  subscriptions: Subscription[] = [];

  constructor(
    private adminService: AdminService,
    private eventsService: EventsService,
    private publicationService: PublicationService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private filesService: FilesService,
    private navbarService: NavbarService,
    private dialog: MatDialog,
    public location: Location,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.initComponent();
    this.initSeriesForm();
  }

  dropContent(event: CdkDragDrop<any[]>) {
    if (event.container.id  == 'cdk-drop-list-0') {
      const elementType = event.previousContainer.data[event.previousIndex].type;
      if (event.previousContainer.id == event.container.id) return;
      if (elementType == ProceedingsContentType.INDEX ||
          elementType == ProceedingsContentType.AUTHOR_INDEX ||
          elementType == ProceedingsContentType.WHITE_PAGE ||
          elementType == ProceedingsContentType.FILE) {
            this.notificationService.openConfirmationDialog({
              title: 'admin.event.publication.proceedings.content.blocked-move',
              content: '',
              cancel: undefined
            });
            return;
          }
    }

    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  }

  dropTrackFile(event: CdkDragDrop<any[]>) {
    if (event.previousContainer.id != event.container.id) return;

    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  removeAllContent() {
    this.notIncludedInContent = this.notIncludedInContent.concat(this.includedInContent.filter(c => c.type == ProceedingsContentType.SESSION || c.type == ProceedingsContentType.SUBMISSION));
    this.includedInContent = this.includedInContent.filter(c => !(c.type == ProceedingsContentType.SESSION || c.type == ProceedingsContentType.SUBMISSION));
  }

  includeAllContent() {
    this.includedInContent = this.includedInContent.concat(this.notIncludedInContent);
    this.notIncludedInContent = [];
  }

  initComponent() {
    this.initIdentificationForm();
    this.initContentStep();
    this.initTrackFileStep();
    this.initIndexesStep();
  }

  sortProceedingsContent(c1: ProceedingsContent, c2: ProceedingsContent) {
    return c1.order < c2.order ? -1 : 1;
  }

  sortTrackFile(f1: FileRules, f2: FileRules) {
    return f1.proceedingsOrder < f2.proceedingsOrder ? -1 : 1;
  }

  uploadProceedingsFile(event: any) {
    const file = event.target.files[0];
    let error = undefined;
    let uploadValid = false;

    const validFile = this.publicationService.validProceedingsFile(file);
    switch (validFile) {
      case FileUploadErrors.MAX_SIZE:
        error = 'errors.file.max-size';
        break;
      case FileUploadErrors.INVALID_FORMAT:
        error = 'errors.file.invalid-format';
        break;
      default:
        error = undefined;
        uploadValid = true;
    }

    if (uploadValid) {
      const formData = new FormData();
      formData.set('file', file);

      this.includedInContent.push(ProceedingsContent.convertFileToProceedingsContent(new File(0, file.name, 0, undefined), this.event.id, 0));

      this.publicationService.submitProceedingsFile(this.event.id, formData).subscribe((event: HttpEvent<Object>) => {
        if (event.type == HttpEventType.Response) {
          const proceedingsContentFile: ProceedingsContent = event.body['data'];
          this.includedInContent.splice(this.includedInContent.length - 1, 1, proceedingsContentFile);
        } else if (event.type == HttpEventType.UploadProgress) {
          const percentDone = Math.round((event.loaded * 100) / event.total);
          this.fileUploadPercent = percentDone;
        }
      });
    } else {
      this.notificationService.openConfirmationDialog({ title: error, content: '' }).subscribe();
    }

    event.target.value = null;
  }


  deleteContentItem(item: ProceedingsContent, index: number) {

    if (item.type == ProceedingsContentType.SESSION || item.type == ProceedingsContentType.SUBMISSION) {
      this.includedInContent.splice(index, 1);
      this.notIncludedInContent.push(item);
    } else {
      this.notificationService.openConfirmationDialog({
        title: 'admin.event.tracks.checklist.confirm-checklistfield-deletion',
        content: '',
      }).subscribe(confirmed => {
        if (!confirmed) return;
        this.includedInContent.splice(index, 1);
        if (item.type == ProceedingsContentType.FILE) {
          this.publicationService.deleteProceedingsFile(item.file.id, this.event.id).subscribe();
        }
      });
    }
  }

  createContent(type: ProceedingsContentType | string) {
    const contentType = type as ProceedingsContentType;
    const contentItem = new ProceedingsContent(contentType, this.includedInContent.length, this.event.id, undefined);

    if (contentType == ProceedingsContentType.INDEX || contentType == ProceedingsContentType.AUTHOR_INDEX) {
      const indexExists = this.includedInContent.filter(c => c.type == contentType).length != 0;

      if (indexExists) {
        this.notificationService.openConfirmationDialog({
          title: 'admin.event.publication.proceedings.content.blocked-create-index',
          content: '',
          cancel: undefined
        });
        return;
      }

    }

    if (contentType == ProceedingsContentType.INDEX) {
      this.includedInContent.unshift(contentItem);
    } else {
      this.includedInContent.push(contentItem);
    }
  }

  initContentStep() {
    if (this.step !== 'Content') return;
    this.includedInContent = this.includedInContent.sort(this.sortProceedingsContent);

    const includedSessionsId = this.includedInContent.filter(i => i.type == ProceedingsContentType.SESSION).map(i => i.session.id);
    const convertedSessions = this.sessions.filter(s => !includedSessionsId.includes(s.id)).map(s => ProceedingsContent.convertSessionToProceedingsContent(s, this.event.id, 0));
    this.notIncludedInContent = convertedSessions;

    const includedSubmissionsId = this.includedInContent.filter(i => i.type == ProceedingsContentType.SUBMISSION).map(i => i.submission.id);
    const convertedSubmissions = this.submissions.filter(s => s.session == null && !includedSubmissionsId.includes(s.id)).map(s => ProceedingsContent.convertSubmissionToProceedingsContent(s, this.event.id, 0));
    this.notIncludedInContent = this.notIncludedInContent.concat(convertedSubmissions);
  }

  initTrackFileStep() {
    if (this.step !== 'TrackFile') return;

    const tasks = [this.publicationService.getProceedingsContent(this.event.id), this.eventsService.getSessionsByEvent(this.event.id), this.submissionsService.getSubmissionsByEvent(this.event.id, {}), this.publicationService.getProceedingsTrackFile(this.event.id)];

    let usedTrackFilesIds: number[];

    forkJoin(tasks).subscribe(([content, sessions, submissions, trackFiles] : [ProceedingsContent[], Session[], Submission[], FileRules[]]) => {
      this.sessions = sessions;
      this.submissions = submissions;
      this.proceedingsTrackFiles = trackFiles;

      usedTrackFilesIds = this.extractIncludedTrackFilesId(content);
      this.tracks = this.event.tracks.filter(t => t.trackFiles.length != 0);

    }, () => {}, () => {
      this.mountTracksLists(usedTrackFilesIds);
      this.initTrackFileForm();
    });
  }

  initTrackFileForm() {

    const savedTrackFiles = this.proceedingsTrackFiles.map(t => t.id);

    this.trackFileForms = this.fb.array(Object.keys(this.tracksLists).map(trackId => this.fb.group({
      id: [trackId]
    })));

    this.trackFileForms.controls.forEach((trackForm) => {
      const form = trackForm as FormGroup;
      const trackId = form.value.id;

      this.tracksLists[trackId].forEach((file, index, array) => {
        const checkedByDefault = savedTrackFiles.includes(file.id) || array.length === 1;
        form.addControl(file.id, new FormControl(checkedByDefault));
      });
    });
  }

  initIndexesStep() {
    if (this.step !== 'Indexes') return;

    const filterIndex = this.includedInContent.filter(c => c.type == ProceedingsContentType.INDEX);
    const filterAuthorIndex = this.includedInContent.filter(c => c.type == ProceedingsContentType.AUTHOR_INDEX);

    this.index = filterIndex.length != 0 ? filterIndex[0] : undefined;
    this.authorIndex = filterAuthorIndex.length != 0 ? filterAuthorIndex[0] : undefined;

    if ((this.index == undefined && this.authorIndex == undefined) || this.proceedingsTrackFiles.length == 0) return;

    this.initIndexesForm();
    this.indexList = this.mountIndex();

    this.indexesForm.get('sessionTitle').valueChanges.subscribe(() => {
      this.indexList = this.mountIndex();
      this.changeDetectorRef.detectChanges();
    });
  }

  initIndexesForm() {
    this.indexesForm = this.fb.group({
      sessionTitle: [this.indexesOptions.sessionTitle],
      letterGrouping: [this.indexesOptions.letterGrouping],
      abbreviatedName: [this.indexesOptions.abbreviatedName]
    });
  }

  getItemTitle(proceedingItem: ProceedingsContent): string {
    switch(proceedingItem.type) {
      case ProceedingsContentType.AUTHOR_INDEX:
        return this.translate.instant('admin.event.publication.proceedings.content.types.author-index');
      case ProceedingsContentType.FILE:
        return proceedingItem.file.name.replace('.pdf', '').replace('api', '');
      case ProceedingsContentType.INDEX:
        return this.translate.instant('admin.event.publication.proceedings.content.types.index');
      case ProceedingsContentType.SESSION:
        return proceedingItem.session.title;
      case ProceedingsContentType.SUBMISSION:
        return proceedingItem.submission.title;
      case ProceedingsContentType.WHITE_PAGE:
        return this.translate.instant('admin.event.publication.proceedings.content.types.white-page');
    }
  }

  calcItemLength(proceedingItem: ProceedingsContent): number {
    switch(proceedingItem.type) {
      case ProceedingsContentType.INDEX:
        const charactersPerLine = 87;
        const linesPerPage = 30;

        let totalLines = 0;

        this.includedInContent.forEach(indexItem => {
          const titleLength = this.getItemTitle(indexItem).length;
          const linesNeeded = titleLength / charactersPerLine;
          totalLines += Math.ceil(linesNeeded);
        });

        const totalPages = Math.ceil(totalLines / linesPerPage);
        return totalPages;
      case ProceedingsContentType.AUTHOR_INDEX:
        return undefined;
      case ProceedingsContentType.FILE:
        return proceedingItem.file.pageCount;
      case ProceedingsContentType.SESSION:
        return 1;
      case ProceedingsContentType.SUBMISSION:
        const submission = this.submissions.filter(s => s.id == proceedingItem.submission.id)[0];
        const includedTrackFilesId = this.proceedingsTrackFiles.map(tf => tf.id);
        const files = submission.files.filter(f => includedTrackFilesId.includes(f.trackFile.id));

        let submissionPageCount = 0;

        files.forEach(f => {
          submissionPageCount += f.pageCount;
        });

        return submissionPageCount;
      case ProceedingsContentType.WHITE_PAGE:
        return 1;
    }
  }

  mountIndex(): ProceedingsIndex[] {
    const indexList: ProceedingsIndex[] = [];

    let convertedContent: ProceedingsContent[] = [];
    this.includedInContent.forEach(cont => {
      convertedContent.push(cont);
      if (cont.type == ProceedingsContentType.SESSION) {
        const session = this.sessions.filter(s => s.id == cont.session.id)[0];
        session.submissions.forEach(submission => {
          convertedContent.push(ProceedingsContent.convertSubmissionToProceedingsContent(submission, undefined, undefined));
        });
      }
    });

    if (!this.indexesForm.get('sessionTitle').value) {
      convertedContent = convertedContent.filter(c => c.type != ProceedingsContentType.SESSION);
    }

    convertedContent.forEach((pi, index) => {

      const lastIndexItem = index != 0 ? indexList[index - 1] : undefined;

      const pageStart = lastIndexItem ? lastIndexItem.pageStart + lastIndexItem.length : 1;
      const length = this.calcItemLength(pi);

      indexList.push(new ProceedingsIndex(pi, this.getItemTitle(pi), pageStart, length));
    });

    return indexList;
  }

  initIdentificationForm() {
    if (this.step !== 'ISBN/ISSN') return;

    this.identificationForm = this.fb.group({
      isbn: [this.event ? this.event.isbn : ''],
      issn: [this.event ? this.event.issn : '']
    });
  }

  justSelectedTracks() {
    const trackFilesIds = this.proceedingsTrackFiles.map(tf => tf.id);

    return this.event.tracks.filter(t => {
      let atLeastOneTrackFile = false;

      for (let tf of t.trackFiles) {
        if (trackFilesIds.includes(tf.id)) {
          atLeastOneTrackFile = true;

          break;
        }
      }

      return atLeastOneTrackFile;
    });
  }

  initSectionsStep(seriesPath: string) {
    if (this.step !== 'Sections') return;

    const tasks = [this.eventsService.getSolSections(seriesPath), this.publicationService.getProceedingsTrackFile(this.event.id)];

    forkJoin(tasks).subscribe(([solSections, trackFiles] : [Object[], FileRules[]]) => {
      this.sectionList = solSections;
      this.loadedSections = true;
      this.proceedingsTrackFiles = trackFiles;
      this.tracks = this.justSelectedTracks();
    }, () => {}, () => {
      this.initSectionsForm();
    });
  }

  initSeriesForm(): void {
    this.solSeriesValues = [
      { id: 'none', value: 'Nenhuma'},
      { id: 'bracis', value: 'Anais da Brazilian Conference on Intelligent Systems (BRACIS)'},
      { id: 'brasnam', value: 'Anais do Brazilian Workshop on Social Network Analysis and Mining (BraSNAM)'},
      { id: 'bresci', value: 'Anais do Brazilian e-Science Workshop (BreSci)'},
      { id: 'bsb', value: 'Anais do Simpósio Brasileiro de Bioinformática (BSB)'},
      { id: 'bsb_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Bioinformática (BSB)'},
      { id: 'bwaif', value: 'Anais do Brazilian Workshop on Artificial Intelligence in Finance (BWAIF)'},
      { id: 'bware', value: 'Anais do Brazilian Workshop on Large-scale Critical Systems (BWare)'},
      { id: 'capaihc', value: 'Anais do Workshop em Culturas, Alteridades e Participações em IHC (CAPAihc)'},
      { id: 'cbie', value: 'Anais do Congresso Brasileiro de Informática na Educação (CBIE)'},
      { id: 'cbie_estendido', value: 'Anais Estendidos do Congresso Brasileiro de Informática na Educação (CBIE)'},
      { id: 'cblockchain', value: 'Anais do Colóquio em Blockchain e Web Descentralizada (CBlockchain)'},
      { id: 'cbsoft', value: 'Anais do Congresso Brasileiro de Software: Teoria e Prática (CBSoft)'},
      { id: 'cbsoft_estendido', value: 'Anais Estendidos do Congresso Brasileiro de Software: Teoria e Prática (CBSoft)'},
      { id: 'cibse', value: 'Anais do Congresso Ibero-Americano em Engenharia de Software (CIbSE)'},
      { id: 'clatg_clei', value: 'Concurso Latino-americano de Trabalhos de Graduação (CLATG_CLEI_LACLO)'},
      { id: 'connect', value: 'Anais da Conferência Connect Tech'},
      { id: 'courb', value: 'Anais do Workshop de Computação Urbana (CoUrb)'},
      { id: 'csbc', value: 'Anais do Congresso da Sociedade Brasileira de Computação (CSBC)'},
      { id: 'ctd', value: 'Anais do Concurso de Teses e Dissertações (CTD)'},
      { id: 'ctic', value: 'Anais do Concurso de Trabalhos de Iniciação Científica da SBC (CTIC-SBC)'},
      { id: 'ctrle', value: 'Anais do Congresso sobre Tecnologias na Educação (Ctrl+e)'},
      { id: 'desafie', value: 'Anais do Workshop de Desafios da Computação aplicada à Educação (DesafIE!)'},
      { id: 'dsw', value: 'Anais do Dataset Showcase Workshop (DSW)'},
      { id: 'educomp', value: 'Anais do Simpósio Brasileiro de Educação em Computação (EDUCOMP)'},
      { id: 'educomp_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Educação em Computação (EDUCOMP)'},
      { id: 'encompif', value: 'Anais do Encontro Nacional de Computação dos Institutos Federais (ENCompIF)'},
      { id: 'eniac', value: 'Anais do Encontro Nacional de Inteligência Artificial e Computacional (ENIAC)'},
      { id: 'enucompi', value: 'Anais do Encontro Unificado de Computação do Piauí (ENUCOMPI)'},
      { id: 'epoca', value: 'Anais da Escola Potiguar de Computação e suas Aplicações'},
      { id: 'eradco', value: 'Anais da Escola Regional de Alto Desempenho do Centro-Oeste (ERAD-CO)'},
      { id: 'erad-eramia-no2', value: 'Anais da Escola Regional de Alto Desempenho Norte 2 (ERAD-NO2) e Escola Regional de Aprendizado de Máquina e Inteligência Artificial Norte 2 (ERAMIA-NO2)'},
      { id: 'eradrj', value: 'Anais da Escola Regional de Alto Desempenho do Rio de Janeiro (ERAD-RJ)'},
      { id: 'eradrs', value: 'Anais da Escola Regional de Alto Desempenho da Região Sul (ERAD-RS)'},
      { id: 'eradsp', value: 'Anais da Escola Regional de Alto Desempenho de São Paulo (ERAD-SP)'},
      { id: 'eramiasp', value: 'Anais da Escola Regional de Aprendizado de Máquina e Inteligência Artificial de São Paulo'},
      { id: 'erbase', value: 'Anais da Escola Regional de Computação Bahia, Alagoas e Sergipe (ERBASE)'},
      { id: 'erbd', value: 'Anais da Escola Regional de Banco de Dados (ERBD)'},
      { id: 'ercas', value: 'Anais da Escola Regional de Computação Aplicada à Saúde (ERCAS)'},
      { id: 'ercemapi', value: 'Anais da Escola Regional de Computação do Ceará, Maranhão e Piauí (ERCEMAPI)'},
      { id: 'ercomprs', value: 'Anais da Escola Regional de Computação do Rio Grande do Sul (ERCOMP-RS)'},
      { id: 'eres', value: 'Anais da Escola Regional de Engenharia de Software (ERES)'},
      { id: 'erigo', value: 'Anais da Escola Regional de Informática de Goiás (ERI-GO)'},
      { id: 'eri-mt', value: 'Anais da Escola Regional de Informática de Mato Grosso (ERI-MT)'},
      { id: 'eripi', value: 'Anais da Escola Regional de Informática do Piauí (ERI-PI)'},
      { id: 'eri-rj', value: 'Anais da Escola Regional de Informática do Rio de Janeiro (ERI-RJ)'},
      { id: 'errc', value: 'Anais da Escola Regional de Redes de Computadores (ERRC)'},
      { id: 'ersi-rj', value: 'Anais da Escola Regional de Sistemas de Informação do Rio de Janeiro (ERSI-RJ)'},
      { id: 'etc', value: 'Anais do Encontro de Teoria da Computação (ETC)'},
      { id: 'ihc', value: 'Anais do Simpósio Brasileiro sobre Fatores Humanos em Sistemas Computacionais  (IHC)'},
      { id: 'ihc_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Fatores Humanos em Sistemas Computacionais (IHC)'},
      { id: 'ise', value: 'Anais do Workshop Brasileiro de Engenharia de Software Inteligente (ISE)'},
      { id: 'jolai_clei', value: 'Jornada Latino-Americana de Atualização em Informática (JoLAI_CLEI_LACLO)'},
      { id: 'kdmile', value: 'Anais do Symposium on Knowledge Discovery, Mining and Learning (KDMiLe)'},
      { id: 'ladc', value: 'Anais do Latin-American Symposium on Dependable Computing (LADC)'},
      { id: 'ladc_estendido', value: 'Anais Estendidos do Latin-American Symposium on Dependable Computing (LADC)'},
      { id: 'latinoware', value: 'Anais do Congresso Latino-Americano de Software Livre e Tecnologias Abertas (Latinoware)'},
      { id: 'lique', value: 'Proceedings of the Life Improvement in Quality by Ubiquitous Experiences Workshop (LIQUE)'},
      { id: 'mssis', value: 'Anais do Workshop em Modelagem e Simulação de Sistemas Intensivos em Software (MSSiS)'},
      { id: 'opensciense', value: 'Anais do Workshop de Práticas de Ciência Aberta para Engenharia de Software (OpenScienSE)'},
      { id: 'rbresd', value: 'Revista Brasileira de Redes de Computadores e Sistemas Distribuídos (RB-RESD)'},
      { id: 'safelife', value: 'Anais do Workshop on Safety, Security, and Privacy in Complex Artificial Intelligence based Systems (Safelife)'},
      { id: 'sast', value: 'Anais do Simpósio Brasileiro de Testes de Software Sistemático e Automatizado (SAST)'},
      { id: 'sbac-pad', value: 'Anais do International Symposium on Computer Architecture and High Performance Computing (SBAC-PAD)'},
      { id: 'sbac-pad_estendido', value: 'Anais Estendidos do International Symposium on Computer Architecture and High Performance Computing (SBAC-PAD)'},
      { id: 'sbbd', value: 'Anais do Simpósio Brasileiro de Banco de Dados (SBBD)'},
      { id: 'sbbd_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Banco de Dados (SBBD)'},
      { id: 'sbcars', value: 'Anais do Simpósio Brasileiro de Componentes, Arquiteturas e Reutilização de Software (SBCARS)'},
      { id: 'sbcars_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Componentes, Arquiteturas e Reutilização de Software (SBCARS)'},
      { id: 'sbcas', value: 'Anais do Simpósio Brasileiro de Computação Aplicada à Saúde (SBCAS)'},
      { id: 'sbcas_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Computação Aplicada à Saúde (SBCAS)'},
      { id: 'sbcci', value: 'Symposium on Integrated Circuits and Systems Design (SBCCI)'},
      { id: 'sbceb', value: 'Anais do Simpósio Brasileiro de Computação na Educação Básica (SBC-EB)'},
      { id: 'sbcm', value: 'Anais do Simpósio Brasileiro de Computação Musical (SBCM)'},
      { id: 'sbcup', value: 'Anais do Simpósio Brasileiro de Computação Ubíqua e Pervasiva (SBCUP)'},
      { id: 'sbes', value: 'Anais do Simpósio Brasileiro de Engenharia de Software (SBES)'},
      { id: 'sbes_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Engenharia de Software (SBES)'},
      { id: 'sbesc', value: 'Anais do Simpósio Brasileiro de Engenharia de Sistemas Computacionais (SBESC)'},
      { id: 'sbesc_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Engenharia de Sistemas Computacionais (SBESC)'},
      { id: 'sbgames', value: 'Anais do Simpósio Brasileiro de Jogos e Entretenimento Digital (SBGames)'},
      { id: 'sbgames_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Jogos e Entretenimento Digital (SBGames)'},
      { id: 'sbiagro', value: 'Anais do Congresso Brasileiro de Agroinformática (SBIAGRO)'},
      { id: 'sbie', value: 'Anais do Simpósio Brasileiro de Informática na Educação (SBIE)'},
      { id: 'sbie_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Informática na Educação (SBIE)'},
      { id: 'sblp', value: 'Anais do Simpósio Brasileiro de Linguagens de Programação (SBLP)'},
      { id: 'sbmf', value: 'Anais do Simpósio Brasileiro de Métodos Formais (SBMF)'},
      { id: 'sbqs', value: 'Anais do Simpósio Brasileiro de Qualidade de Software (SBQS)'},
      { id: 'sbqs_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Qualidade de Software (SBQS)'},
      { id: 'sbrc', value: 'Anais do Simpósio Brasileiro de Redes de Computadores e Sistemas Distribuídos (SBRC)'},
      { id: 'sbrc_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Redes de Computadores e Sistemas Distribuídos (SBRC)'},
      { id: 'sbrlars', value: 'Anais do Simpósio Brasileiro de Robótica e Simpósio Latino Americano de Robótica (SBR/LARS)'},
      { id: 'sbrlars_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Robótica e Simpósio Latino-Americano de Robótica (SBR/LARS)'},
      { id: 'sbsc', value: 'Anais do Simpósio Brasileiro de Sistemas Colaborativos (SBSC)'},
      { id: 'sbsc_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Sistemas Colaborativos (SBSC)'},
      { id: 'sbseg', value: 'Anais do Simpósio Brasileiro de Segurança da Informação e de Sistemas Computacionais (SBSeg)'},
      { id: 'sbseg_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Segurança da Informação e de Sistemas Computacionais (SBSeg)'},
      { id: 'sbsi', value: 'Anais do Simpósio Brasileiro de Sistemas de Informação (SBSI)'},
      { id: 'sbsi_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Sistemas de Informação (SBSI)'},
      { id: 'seadco', value: 'Anais do Seminário de Educação a Distância da Região Centro-Oeste (SEAD-CO)'},
      { id: 'semiedu', value: 'Anais Principais do Seminário de Educação (SemiEdu)'},
      { id: 'semiedu_estendido', value: 'Anais Estendidos do Seminário de Educação'},
      { id: 'semish', value: 'Anais do Seminário Integrado de Software e Hardware (SEMISH)'},
      { id: 'sensoryx', value: 'Proceedings of the Workshop on Multisensory Experiences'},
      { id: 'sibgrapi', value: 'Anais da Conference on Graphics, Patterns and Images (SIBGRAPI)'},
      { id: 'sibgrapi_estendido', value: 'Anais Estendidos da Conference on Graphics, Patterns and Images (SIBGRAPI)'},
      { id: 'sscad', value: 'Anais do Simpósio em Sistemas Computacionais de Alto Desempenho (SSCAD)'},
      { id: 'sscad_estendido', value: 'Anais Estendidos do Simpósio em Sistemas Computacionais de Alto Desempenho (SSCAD)'},
      { id: 'stamp', value: 'Anais do Workshop on System-Theoretic Accident Model and Processes (STAMP)'},
      { id: 'stil', value: 'Anais do Simpósio Brasileiro de Tecnologia da Informação e da Linguagem Humana (STIL)'},
      { id: 'svr', value: 'Anais do Simpósio de Realidade Virtual e Aumentada (SVR)'},
      { id: 'svr_estendido', value: 'Anais Estendidos do Simpósio de Realidade Virtual e Aumentada (SVR)'},
      { id: 'vem', value: 'Anais do Workshop de Visualização, Evolução e Manutenção de Software (VEM)'},
      { id: 'w6g', value: 'Anais do Workshop de Redes 6G (W6G)'},
      { id: 'wafers', value: 'Anais do Workshop on Validation and Verification of Future Cyber-physical Systems (WAFERS)'},
      { id: 'waihcws', value: 'Anais do Workshop sobre Aspectos da Interação Humano-Computador na Web Social (WAIHCWS)'},
      { id: 'wamps', value: 'Anais do Workshop Anual do MPS (WAMPS)'},
      { id: 'wapla', value: 'Anais do Workshop de Aplicações Práticas de Learning Analytics em Instituições de Ensino no Brasil (WAPLA)'},
      { id: 'washes', value: 'Anais do Workshop sobre Aspectos Sociais, Humanos e Econômicos de Software (WASHES)'},
      { id: 'wave', value: 'Anais do Workshop on Advanced Virtual Environments and Education (WAVE)'},
      { id: 'wbci', value: 'Anais do Workshop Brasileiro de Cidades Inteligentes (WBCI)'},
      { id: 'wbl', value: 'Anais do Workshop Brasileiro de Lógica (WBL)'},
      { id: 'wblockchain', value: 'Workshop em Blockchain: Teoria, Tecnologias e Aplicações (WBlockchain)'},
      { id: 'wcama', value: 'Anais do Workshop de Computação Aplicada à Gestão do Meio Ambiente e Recursos Naturais (WCAMA)'},
      { id: 'wcga', value: 'Anais do Workshop em Clouds e Aplicações (WCGA)'},
      { id: 'wcge', value: 'Anais do Workshop de Computação Aplicada em Governo Eletrônico (WCGE)'},
      { id: 'weadeh', value: 'Anais do Workshop de Educação a Distância e Ensino Híbrido (WEADEH)'},
      { id: 'webmedia', value: 'Anais do Simpósio Brasileiro de Sistemas Multimídia e Web (WebMedia)'},
      { id: 'webmedia_estendido', value: 'Anais Estendidos do Simpósio Brasileiro de Sistemas Multimídia e Web (WebMedia)'},
      { id: 'wei', value: '	Anais do Workshop sobre Educação em Computação (WEI)'},
      { id: 'weihc', value: 'Anais do Workshop sobre Educação em IHC (WEIHC)'},
      { id: 'weit', value: 'Anais do Workshop-Escola de Informática Teórica (WEIT)'},
      { id: 'wetie', value: 'Anais do Workshop em Estratégias Transformadoras e Inovação na Educação (WETIE)'},
      { id: 'wfc', value: 'Anais do Workshop de Forense Computacional'},
      { id: 'wfibre', value: 'Anais do Workshop do Testbed FIBRE (WFIBRE)'},
      { id: 'wgid', value: 'Anais do Workshop de Gestão de Identidades Digitais'},
      { id: 'wgrs', value: 'Anais do Workshop de Gerência e Operação de Redes e Serviços (WGRS)'},
      { id: 'wics', value: 'Anais do Workshop sobre as Implicações da Computação na Sociedade (WICS)'},
      { id: 'wide', value: 'Anais do Workshop Investigações em Interação Humano-Dados (WIDE)'},
      { id: 'wie', value: 'Anais do Workshop de Informática na Escola (WIE)'},
      { id: 'wiplay', value: 'Anais do Workshop sobre Interação e Pesquisa de Usuários no Desenvolvimento de Jogos (WIPlay)'},
      { id: 'wit', value: 'Anais do Women in Information Technology (WIT)'},
      { id: 'wlatac_clei', value: 'Workshop Latino-Americano de Trabalhos em Andamento em Computação (WLATAC_CLEI_LACLO)'},
      { id: 'wpci', value: 'Anais do Workshop de Pensamento Computacional e Inclusão (WPCI)'},
      { id: 'wpeif', value: 'Anais do Workshop de Pesquisa Experimental da Internet do Futuro (WPEIF)'},
      { id: 'wperformance', value: 'Anais do Workshop em Desempenho de Sistemas Computacionais e de Comunicação (WPerformance)'},
      { id: 'wpietf', value: 'Anais do Workshop Pré-IETF (WPIETF)'},
      { id: 'wquantum', value: 'Anais do Workshop de Comunicação e Computação Quântica (WQuantum)'},
      { id: 'wrac', value: 'Anais do Workshop de Regulação, Avaliação da Conformidade e Certificação de Segurança'},
      { id: 'wscdc', value: 'Anais do Workshop de Segurança Cibernética em Dispositivos Conectados (WSCDC)'},
      { id: 'wsensing', value: 'Anais do Workshop on Security, Privacy and Reliability on Wireless Sensing Networks (WSENSING)'},
      { id: 'wslice', value: 'Anais do Workshop de Teoria, Tecnologias e Aplicações de Slicing para Infraestruturas Softwarizadas (WSlice)'},
      { id: 'wte', value: 'Anais do Workshop de Tecnologia Eleitoral'},
      { id: 'wtestbeds', value: 'Anais do Workshop de Testbeds'},
      { id: 'wtf', value: 'Anais do Workshop de Testes e Tolerância a Falhas (WTF)'},
      { id: 'wtrans', value: 'Anais do Workshop de Transparência em Sistemas (WTranS)'},
      { id: 'wvc', value: 'Anais do Workshop de Visão Computacional (WVC)'},
      { id: 'xr_in_games', value: 'Proceedings of the XR in Games Workshop'}
    ];

    this.seriesForm = this.fb.group({
      solSeries: [undefined, Validators.required]
    });

    this.seriesForm.get('solSeries').valueChanges.subscribe(series => {
      this.solSeriesSelected = series !== 'none';

      if (this.solSeriesSelected)
        this.initSectionsStep(series);
    });
  }

  initSectionsForm() {
    this.sectionForms = this.fb.array(this.tracks.map(track => this.fb.group({
      id: [track.id],
      idTrackFile: [track.trackFiles[0].id.toString()],
      sections: [undefined, Validators.required]
    })));
  }

  setTrackFile(trackFileId, track) {
    const form = this.getSectionFormGroup(track);

    form.patchValue({ idTrackFile: trackFileId.toString() })
  }

  getSectionFormGroup(track: Track): FormGroup {
    return this.sectionForms.controls.filter(formGroup => formGroup.value.id == track.id)[0] as FormGroup;
  }

  sectionsStepListOfOptions(track, proceedingsTrackFiles) {
    if (!track) return [];

    const proceedingsTrackFilesIds = proceedingsTrackFiles.map((tf) => tf.id);
    const listOfOptions = [];

    for (let tf of track.trackFiles) {
      if (proceedingsTrackFilesIds.includes(tf.id)) listOfOptions.push({ value: tf.name, id: tf.id });
    }

    return listOfOptions;
  }

  extractIncludedTrackFilesId(content: ProceedingsContent[]): number[] {

    const subs = [];

      content.forEach(c => {
        if (c.type == ProceedingsContentType.SUBMISSION) {
          const includedSubmission = this.submissions.filter(s => s.id == c.submission.id)[0];
          subs.push(includedSubmission);
        } else if (c.type == ProceedingsContentType.SESSION) {
          const includedSession = this.sessions.filter(s => s.id == c.session.id)[0];
          includedSession.submissions.forEach(iss => {
            const includedSessionSubmission = this.submissions.filter(s => s.id == iss.id)[0];
            subs.push(includedSessionSubmission);
          });
        }
      });

    const ids = new Set();

    subs.forEach(sId => {
      sId.files.forEach(file => {
        ids.add(file.trackFile.id);
      });
    });

    return Array.from(ids) as number[];
  }

  mountTracksLists(usedTrackFilesIds: number[]) {
    this.tracks.forEach(track => {

      const trackFiles = track.trackFiles.filter(trackFile => usedTrackFilesIds.includes(trackFile.id)).sort(this.sortTrackFile);

      if (trackFiles.length != 0) {
        this.tracksLists[track.id] = trackFiles;
      }

    });
  }

  extractSelectedTrackFiles() {
    const data = _.cloneDeep(this.tracksLists);
    let includedTrackFilesIds = [];

    this.trackFileForms.value.forEach(trackForm => {
      const checkedTrackFiles = Object.keys(trackForm).map(key => parseInt(key)).filter(id => !isNaN(id) && trackForm[id]);
      includedTrackFilesIds = includedTrackFilesIds.concat(checkedTrackFiles);
    });

    Object.keys(data).forEach(key => {
      data[key] = data[key].map(file => file.id).filter(id => includedTrackFilesIds.includes(id));
    });

    return data;
  }

  getFormGroup(track: Track): FormGroup {
    return this.trackFileForms.controls.filter(formGroup => formGroup.value.id == track.id)[0] as FormGroup;
  }

  hasAtLeastOneTrackFileSelected(track: Track): boolean {
    return this.extractSelectedTrackFiles()[track.id]?.length !== 0;
  }

  canProceed(): boolean {
    switch(this.step) {
      case 'ISBN/ISSN':
        return this.identificationForm?.valid;
      case 'Content':
      case 'Indexes':
        return true;
      case 'Sections':
        if ([null, 'none'].includes(this.seriesForm.value['solSeries']))
          return false

        let sectionFormValid = true;

        if (this.sectionForms) {
          for (let f of this.sectionForms.controls) {
            if (!f.value['sections']) {
              sectionFormValid = false;
              break;
            }
          }
        }

        return sectionFormValid;
      case 'TrackFile':
        let trackFormValid = true;
        this.tracks?.forEach(t => {
          const trackIsValid = this.hasAtLeastOneTrackFileSelected(t);
          if (!trackIsValid) {
            trackFormValid = trackIsValid;
          }
        });

        return trackFormValid;
      default:
        return false;
    }
  }

  getSectionTitle(sectionId: string): string {
    for (let section of this.sectionList) {
      if (section['id'] === sectionId)
        return section['value'];
    }

    return '';
  }

  getSectionTitleEn(sectionId: string): string {
    for (let section of this.sectionList) {
      if (section['id'] === sectionId)
        return section['valueEn'];
    }

    return '';
  }

  downloadSolSpreadsheets() {
    this.navbarService.loadingStart();
    this.loadingLink = true;

    const relationsTrackFileSection = this.sectionForms.controls.map(f => {
      const trackId        = f.value['id'],
            trackFileId    = f.value['idTrackFile'],
            sectionAbbrev  = f.value['sections'].split(':')[1],
            sectionTitle   = this.getSectionTitle(f.value['sections']),
            sectionTitleEn = this.getSectionTitleEn(f.value['sections']);

      return [ trackId, trackFileId, sectionAbbrev, sectionTitle, sectionTitleEn ];
    });

    const tasks = [this.publicationService.getProceedingsContent(this.event.id)];

    forkJoin(tasks).subscribe(([content] : [ProceedingsContent[]]) => {
      const proceedingIds = content.sort(this.sortProceedingsContent).filter((c) => ['SUBMISSION', 'SESSION'].includes(c.type)).map((s) => s.id);

      this.subscriptions.push(this.eventsService.getSolSpreadsheets(this.event.id, proceedingIds, relationsTrackFileSection).subscribe(
        response => {
          this.link = response.link;
          this.articlesNotUsingSbcTemplate = response.articlesNotUsingSbcTemplate;
          this.loadingLink = false;
        }, error => {
          this.notificationService.notifyError('errors.error-create-link');
          this.loadingLink = false;
        }, () => {
          if (this.link) {
            let downloadURL = this.link
            let contentType = 'application/zip';
            let name = this.event.acronym + 'SOL_Spreadsheets'
            this.subscriptions.push(this.eventsService.downloadSolSpreadsheets(this.link).subscribe((data) => {
              let blob = new Blob([data], {type: contentType});
              downloadURL = URL.createObjectURL(blob);
            }, () => {},
            () => {
              this.navbarService.loadingStop();
              this.downloadLink(downloadURL, name);
            }));
          } else if (this.articlesNotUsingSbcTemplate) {
            this.navbarService.loadingStop();
            this.dialog.open(ArticlesListCardDialogComponent, { width: '100rem', data: { articlesList: this.articlesNotUsingSbcTemplate }});
          }
        }));
    });
  }

  downloadLink(downloadURL: string, name: string) {
    // Create link to lownload
    let link = document.createElement('a');
    link.href = downloadURL;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = name;
    link.click();
  }

  partialSubmit(currentStep: string) {
    switch(currentStep) {
      case 'ISBN/ISSN':
        const identificationData = this.identificationForm.value;
        this.publicationService.editProceedingIdentificationInfo(this.event.id, identificationData).subscribe(res => {
          this.event.isbn = res.data['isbn'];
          this.event.issn = res.data['issn'];
        });
        break;
      case 'Content':
        this.includedInContent.forEach((item, index) => {
          item.order = index + 1;
        });
        this.publicationService.editProceedingContent(this.event.id, { 'content': this.includedInContent}).subscribe(pc => {
          this.includedInContent = pc.sort(this.sortProceedingsContent);
        });
        break;
      case 'Indexes':
        this.publicationService.editProceedingsViewingOptions(this.event.id, this.indexesForm.value).subscribe(indexesOptions => {
          this.indexesOptions = indexesOptions;
        });
        break;
      case 'TrackFile':
        const selectedTrackFiles = this.extractSelectedTrackFiles();

        this.publicationService.editProceedingsTrackFile(this.event.id, { 'trackFiles': selectedTrackFiles }).subscribe(trackFiles => {
          this.proceedingsTrackFiles = trackFiles;
        })

        break;
    }
  }

  submit() {}

}
