import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { forkJoin } from 'rxjs';
import { Event } from 'src/app/model/event';
import { FileRules } from 'src/app/model/file.rules';
import { Submission, SubmissionStatus } from 'src/app/model/paper';
import { ProceedingsContent } from 'src/app/model/proceedingsContent';
import { ProceedingsViewingOptions } from 'src/app/model/proceedingsViewingOptions';
import { Session } from 'src/app/model/session';
import { AdminService } from 'src/app/service/admin.service';
import { EventsService } from 'src/app/service/events.service';
import { PublicationService } from 'src/app/service/publication.service';
import { SubmissionsService } from 'src/app/service/submissions.service';

@Component({
  selector: 'app-event-publication-proceedings',
  templateUrl: './event-publication-proceedings.component.html',
  styleUrls: ['./event-publication-proceedings.component.scss']
})

export class EventPublicationProceedingsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;

  steps: { name: string, label: string }[] = [
    {name: 'ISBN/ISSN', label: 'admin.event.publication.proceedings.steps.identification'},
    {name: 'Content', label: 'admin.event.publication.proceedings.steps.content'},
    {name: 'TrackFile', label: 'admin.event.publication.proceedings.steps.track-file'},
    {name: 'Sections', label: 'admin.event.publication.proceedings.steps.sections'},
    {name: 'Indexes', label: 'admin.event.publication.proceedings.steps.indexes'},
    {name: 'Preview', label: 'admin.event.publication.proceedings.steps.preview'},
    {name: 'Generate', label: 'admin.event.publication.proceedings.steps.generate'},
  ];

  event: Event;
  submissions: Submission[];
  sessions: Session[];
  content: ProceedingsContent[];
  selectedTrackFiles: FileRules[];
  indexesOptions: ProceedingsViewingOptions;

  constructor(
    private adminService: AdminService,
    private eventsService: EventsService,
    private publicationService: PublicationService,
    private submissionsService: SubmissionsService,
  ) { }

  ngOnInit(): void {
    this.event = this.adminService.selectedEvent;
    if (!this.event) {
      this.adminService.getEvent().subscribe(event => {
        this.event = event;
        this.initComponent();
      });
    } else {
      this.initComponent();
    }
  }

  ngAfterViewInit(): void {
    this.stepper._getIndicatorType = () => 'number';
  }

  initComponent() {
    this.adminService.progressBar.start();

    const params = {
      detailed: true,
      status: SubmissionStatus.ACCEPTED
    };

    const tasks = [
      this.submissionsService.getSubmissionsByEventMinimum(this.event.id),
      this.eventsService.getSessionsByEvent(this.event.id),
      this.publicationService.getProceedingsContent(this.event.id),
      this.publicationService.getProceedingsTrackFile(this.event.id),
      this.publicationService.getProceedingsViewingOptions(this.event.id)
    ];

    forkJoin(tasks).subscribe(([submissions, sessions, content, trackFiles, indexesOptions] : [Submission[], Session[], ProceedingsContent[], FileRules[], ProceedingsViewingOptions]) => {
      this.submissions = submissions;
      this.sessions = sessions;
      this.content = content;
      this.selectedTrackFiles = trackFiles;
      this.indexesOptions = indexesOptions;
    }, () => {}, () => {
      this.adminService.progressBar.stop();
    });
  }

  isStepReady(step: { name: string, label: string }) {
    switch(step.name) {
      case 'Content':
        return this.content && this.submissions && this.sessions;
      case 'TrackFile':
      case 'Sections':
        return this.content && this.submissions && this.sessions && this.selectedTrackFiles;
      case 'Indexes':
        return this.content && this.submissions && this.sessions && this.selectedTrackFiles && this.indexesOptions;
      case 'ISBN/ISSN':
      case 'Preview':
      case 'Generate':
        return true;
    }
  }

  extractStepsNames() : string[] {
    return this.steps.map(s => s.name);
  }
}
