import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventsFilter'
})
export class EventsFilterPipe implements PipeTransform {

  public transform(value, keys: string, term: string) {
    // If there is no string, return all events
    if (!term) {
      return value;
    }

    const matching_tests = keys.split(',');

    // Else, filter to consider only the events that has some match with the term
    return (value || []).filter(item => matching_tests.some(
      key => {
        let test='';
        // Split by '+' for parallel tests (e.g. 'acronym+year')
        key.split('+').forEach(field => {
          if(item.hasOwnProperty(field)){
            test += item[field] || '';
            test += ' ';
          }
        });
        return new RegExp(term, 'gi').test(test)
      }
    ));
  }
}
