import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-math-captcha',
  templateUrl: './math-captcha.component.html',
  styleUrls: ['./math-captcha.component.scss']
})
export class MathCaptchaComponent implements OnInit {

  constructor() { }

  public MathProblem: string;
  public userInput: number;
  public Response: number;
  public isResolved: boolean;
  @Output() resolved = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.generateMathProblem();
  }

  private generateMathProblem() {
    let num1 = Math.floor(Math.random() * 15) + 1;
    let num2 = Math.floor(Math.random() * 15) + 1;

    let signal = Math.random();

    if (signal > .5) {
      this.Response = num1 + num2;
      this.MathProblem = `${num1} + ${num2}`;
    } else {
      this.Response = num1 - num2;
      this.MathProblem = `${num1} - ${num2}`;
    }

    this.generateCaptchaImg(this.MathProblem);
  }

  public ValidateMathProblem(response: Number) {

    this.isResolved = response === this.Response;
    this.resolved.emit(this.isResolved);

    if (!this.isResolved) {
      this.generateMathProblem();
    }
  }

  onSubmit(): void {
    this.ValidateMathProblem(this.userInput)
    this.userInput = null;
  }

  public getStatus() {
    return this.isResolved;
  }

  reload(): void {
    this.generateMathProblem();
  }

  private generateCaptchaImg(text) {
    const FONT_FAMILY = "Arial";
    const SCALE_X_MIN = 1;
    const SCALE_X_MAX = 1.25;
    const SCALE_Y_MIN = 1;
    const SCALE_Y_MAX = 1.05;
    const TEXT_SIZE_RATIO_MIN = 0.25;
    const TEXT_SIZE_RATIO_MAX = 0.25;
    const SKEW_X_MIN = -0.05;
    const SKEW_X_MAX = 0.15;
    const SKEW_Y_MIN = -0.05;
    const SKEW_Y_MAX = 0.15;
    const TEXT_SCALE_X_FACTOR = 1.5;
    const TEXT_X_RANDOM_FACTOR_MIN = 0.9;
    const TEXT_X_RANDOM_FACTOR_MAX = 1.1;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const container = document.getElementById('canvas-container');

    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;
    const fontSize = canvas.height * (TEXT_SIZE_RATIO_MIN + (Math.random() * TEXT_SIZE_RATIO_MAX));

    ctx.font = `${fontSize}px ${FONT_FAMILY}`;

    // Distortions
    const scaleX = SCALE_X_MIN + (Math.random() * (SCALE_X_MAX - SCALE_X_MIN));
    const scaleY = SCALE_Y_MIN + (Math.random() * (SCALE_Y_MAX - SCALE_Y_MIN));
    ctx.setTransform(scaleX, 0, 0, scaleY, 0, 0);

    const skewX = Math.random() * (SKEW_X_MAX - SKEW_X_MIN) + SKEW_X_MIN;
    const skewY = Math.random() * (SKEW_Y_MAX - SKEW_Y_MIN) + SKEW_Y_MIN;
    ctx.transform(1, skewY, skewX, 1, 0, 0);

    const textWidth = ctx.measureText(text).width;
    const maxTextX = canvas.width - (textWidth * TEXT_SCALE_X_FACTOR);
    const textX = (maxTextX / 2) * (TEXT_X_RANDOM_FACTOR_MIN + Math.random() * (TEXT_X_RANDOM_FACTOR_MAX - TEXT_X_RANDOM_FACTOR_MIN));

    const textY = canvas.height / 2;

    ctx.fillStyle = 'black';
    ctx.fillText(text, textX, textY);

    container.innerHTML = '';
    container.appendChild(canvas);
  }
}
