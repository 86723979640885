import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { ReviewsService } from '../../../../service/reviews.service';
import { Event } from '../../../../model/event';
import { AdminService } from '../../../../service/admin.service';
import { Review } from '../../../../model/paper';
import { Submission } from '../../../../model/paper';
import { ReviewsConfigurationService } from '../../../../service/reviews.configuration.service';
import { User } from '../../../../model/user';
import { ReviewStatus } from '../../../../enum/review.status';
import { UserEventRole } from '../../../../enum/user.event.role';
import { map } from 'rxjs/operators';
import { UserEventAnswer } from '../../../../enum/user.event.answer';
import { EventMembersComponent } from '../../event-people/event-members/event-members.component';
import { zip } from 'rxjs';
import { UserEvent } from '../../../../model/user.event';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { ReviewStatusPerReviewerList } from '../../../../model/review.status.list';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export enum FilterState {
  ONLY_TPC,
  NO_TPC,
  UNFILTERED
}

@Component({
  selector: 'app-review-status-per-user',
  templateUrl: './review-status-per-user.component.html',
  styleUrls: ['./review-status-per-user.component.scss']
})
export class ReviewStatusPerUserComponent implements OnInit {
  event: Event;
  reviewsRequired: number;
  dataSource: MatTableDataSource<ReviewStatusPerReviewerList>;
  reviewStatusPerReviewerList: Array<ReviewStatusPerReviewerList>;
  columns = ['id', 'user', 'tpcMember', 'totalReviews', 'assigned', 'notified', 'confirmed', 'declined', 'reminded', 'late', 'completed', 'draft', 'assignToOthers', 'assignToOthersCompleted', 'firstCompletedReview', 'lastCompletedReview', 'avgReviewsLength'];
  tpcFilter = FilterState.UNFILTERED;

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.dataSource.sort = this.sort;

      this.dataSource.sortingDataAccessor = (item: ReviewStatusPerReviewerList, header: string) => {
        switch (header) {
          case 'id': return item.id;
          case 'user': return item.name;
          case 'tpcMember': return item.tpcMember ? 1 : 0; // Convert boolean to integer, fastest way.
          case 'totalReviews': return item.totalReviews;
          case 'avgReviewsLength': return this.getAVG(item.reviewsLength);
          default: return item[header]; // Default is status columns
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public reviewsService: ReviewsService,
    public reviewConfigurationService: ReviewsConfigurationService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.adminService.progressBar.start();

      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getReviewsPerReviewerList();
        });
      } else {
        this.getReviewsPerReviewerList();
      }
    });
  }

  getReviewsPerReviewerList() {
    this.adminService.progressBar.start();

    this.dataSource = new MatTableDataSource<ReviewStatusPerReviewerList>();

    zip(
      this.reviewsService.getReviewStatusPerReviewerListByEvent(this.event.id),
      this.reviewConfigurationService.getReviewsConfiguration(this.event.id)
    )
    .pipe(map(([reviewStatusPerReviewer, reviewConfiguration]) => ({ reviewStatusPerReviewer, reviewConfiguration })))
    .subscribe(({ reviewStatusPerReviewer, reviewConfiguration }) => {
      this.reviewStatusPerReviewerList = reviewStatusPerReviewer;
      this.reviewsRequired = reviewConfiguration.numberOfReviewsForTpcMember;
      this.dataSource.data = [...this.reviewStatusPerReviewerList];

      this.adminService.progressBar.stop();
    });
  }

  getClass(reviewStatusPerReviewer: ReviewStatusPerReviewerList): string {
    const total = reviewStatusPerReviewer.totalReviews;

    if (total == reviewStatusPerReviewer.completed + reviewStatusPerReviewer.assignToOthersCompleted) {
      return 'exactly-completed';
    } else if (total < this.reviewsRequired) {
      return 'less';
    } else if (total > this.reviewsRequired) {
      return 'more';
    } else {
      return 'exactly';
    }
  }

  getAVG(reviewsLength: Array<number>): number {
    if (reviewsLength.length > 0) {
      return reviewsLength.reduce((acc, value) => acc + value, 0) / reviewsLength.length;
    }
    return 0;
  }

  getAVGTotalReviewsLength(): number {
    if (this.dataSource.data.length > 0) {
      return this.dataSource.data.map(e => this.getAVG(e.reviewsLength)).reduce((acc, value) => acc + value, 0) / this.dataSource.data.length;
    }
    return 0;
  }

  getTotalUsers(): number {
    return this.dataSource.data.length;
  }

  getTotalTPC(): number {
    return this.dataSource.data.filter(e => e.tpcMember == true).length;
  }

  getTotalReviews(): number {
    return this.dataSource.data.map(e => e.totalReviews).reduce((acc, value) => acc + value, 0);
  }

  getTotalStatus(status: string): number {
    return this.dataSource.data.map(e => e[status]).reduce((acc, value) => acc + value, 0);
  }

  toggleTPCFilter() {
   if (this.tpcFilter === FilterState.UNFILTERED) {
     this.tpcFilter = FilterState.ONLY_TPC;
   } else if (this.tpcFilter === FilterState.ONLY_TPC) {
     this.tpcFilter = FilterState.NO_TPC;
   } else {
     this.tpcFilter = FilterState.UNFILTERED;
   }

   this.reviewStatusPerReviewerList.forEach(item => {
     switch (this.tpcFilter) {
       case FilterState.ONLY_TPC:
         item.filtered = !item.tpcMember;
         break;
       case FilterState.NO_TPC:
         item.filtered = item.tpcMember;
         break;
       case FilterState.UNFILTERED:
         item.filtered = false;
     }
   });

   this.dataSource.data = [...this.reviewStatusPerReviewerList.filter(e => !e.filtered)];
 }
}
