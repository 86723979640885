import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileRules } from 'src/app/model/file.rules';

export interface DialogData {
  articlesList?: Array<Object>
}

@Component({
  selector: 'app-articles-list-card-dialog',
  templateUrl: './articles-list-card-dialog.component.html',
  styleUrls: ['./articles-list-card-dialog.component.scss']
})
export class ArticlesListCardDialogComponent {
  @Input() articlesList : Array<Object>;

  constructor(
    public dialogRef: MatDialogRef<ArticlesListCardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.articlesList = data.articlesList;
  }

  openSubmissionById(submissionId) {
    window.open(`/submissions/${submissionId}`, '_blank');
  }
}
