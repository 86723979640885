import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/service/notification.service';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from '../../../../model/event';
import { FileType } from 'src/app/enum/file.type';
import { SelectOption } from 'src/app/model/select.option';
import { Track } from 'src/app/model/track';
import { EventsService } from 'src/app/service/events.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src//app/component/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-event-tracks',
  templateUrl: './event-tracks.component.html',
  styleUrls: ['./event-tracks.component.scss']
})
export class EventTracksComponent implements OnInit {
  event: Event;
  eventTimeZone: string;
  trackForm: FormGroup;

  mimetypeValues: Array<SelectOption> = FileType.toSelectableInvert();

  trackSource: Track[] = [];
  trackColumns = ['name', 'openAt', 'closeAt', 'button_edit', 'button_delete'];

  constructor(
    private adminService: AdminService,
    private eventService: EventsService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public notificationService: NotificationService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initTrackForm();
        });
      } else {
        this.initTrackForm();
      }
    });
  }

  private initTrackForm(): void {
    this.trackForm = this.fb.group({
      tracks: this.fb.array(this.event.tracks.map(track => this.fb.group({
        id: [track.id],
        name: [track.name],
        openAt: [track.openAt],
        closeAt: [track.closeAt],
        trackFiles: this.fb.array(track.trackFiles.map(file => this.fb.group({
          id: [file.id],
          maxPages: [file.maxPages],
          maxSize: [file.maxSize],
          mimetypes: [file.mimetypes.map(e => e.name)],
          name: [file.name],
          required: [file.required]
        }
        )))
      })))
    });
    this.trackSource = this.trackForm.value.tracks;
    this.eventTimeZone = this.event.eventInfo.timeZone;
  }

  deleteTrack(track: Track, trackId: number, trackIndex: number): void {
      this.DialogConfirmation(() => {
        this.notificationService.notify('admin.event.tracks.deleting-track', {
          params: {
            track: `#${track.id} "${track.name}"`
          }
        });
        this.eventService.deleteTrack(trackId, this.event).subscribe(() => {
          const control = (<FormArray>this.trackForm.get('tracks'));
          control.removeAt(trackIndex);
          this.trackSource = this.trackForm.value.tracks;

          this.adminService.getEvent().subscribe(event => {
            this.adminService.selectedEvent = event;
          });

          this.notificationService.notify('admin.event.tracks.deleted-track', {
            params: {
              track: `#${track.id} "${track.name}"`
            }
          });
        });
      });
  }

  DialogConfirmation(callback: () => void) {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'confirmDeleteTrack',
        content: ''
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        callback();
      }
    });
  }
}
