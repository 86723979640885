import { Track } from './track';
import { BaseModel, toDayjs } from './baseModel';
import { Type } from 'class-transformer';
import * as dayjs from 'dayjs';
import { FileType } from './fileType';
import { Transform } from 'class-transformer';

export class FileRules extends BaseModel {
  @Type(() => FileType)
  public mimetypes: Array<FileType>;

  @Type(() => FileType)
  public allowPagesExceeded?: boolean;

  @Transform(toDayjs)
  public openAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public closeAt: dayjs.Dayjs;

  public uploadStatus: number;

  public proceedingsOrder: number;
  public includedInProceedings: boolean;

  constructor(
    public id: number | null,
    public maxPages: number,
    public maxSize: number,
    public name: string,
    public required: boolean = false,
    public track: Track,
    public visibleSessionChair: boolean = false,
    public visibleReviewer: boolean = true,
    public useOfSbcTemplate: boolean = false
  ) { super(); }

  public get isOpenForUpload(): boolean {
    return !this.closed && this.open;
  }

  public get date(): any {
    return dayjs();
  }

  public get closed(): boolean {
    return dayjs() > this.closeAt;
  }

  public get open(): boolean {
    return dayjs() > this.openAt;
  }
}
