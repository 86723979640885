import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { User } from '../../model/user';
import { Observable, Subscription } from 'rxjs';
import { SystemService } from '../../service/system.service';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import { InfoService } from 'src/app/service/info.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOption } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/service/auth.service';
import { of } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogCreateUserComponent } from '../dialog-create-user/dialog-create-user.component';
import { AdminService } from 'src/app/service/admin.service';
import { ActivatedRoute } from '@angular/router';

const MIN_LENGTH_SEARCH = 3;
const ID_NEW_USER_OPTION = -1;

export class UserEventLimit {
  event: number;
  role: UserEventRole;
  exclude_role: boolean;
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(200)]),
      transition(':leave', animate(200, style({ opacity: 0 })))
    ])
  ]
})
export class UserSearchComponent implements OnInit, AfterContentChecked {
  @Input() formGroupController: FormGroup;
  @Input() name: string;
  @Input() includeLoggedUser = false;
  @Input() includePetitionerUser = false;
  @Input() singleUserSearch: string | boolean = false;
  @Input() showSingleUser: string | boolean = false;
  @Input() includeUsers: User[];

  @Input() forbiddenUsers: User[] = [];

  @Input() warningUsers: User[] = [];
  @Input() warningLabel: string;
  @Input() compactView = false;

  @Input() enabledNewUserOption = false;

  stateCtrl = new FormControl('', Validators.required);
  filteredUsers: Observable<User[]> = of([]);
  $filteredUsers: Subscription;
  @Output() searchFoundUsers = new EventEmitter<{ found: boolean, value: boolean }>();
  @Output() addedUser = new EventEmitter<User>();
  selectedOption: MatOption;

  @ViewChildren('userNativeInput') input: QueryList<ElementRef>;
  @ViewChild('userNativeInput') searchInput: ElementRef;

  @Input() userEventLimit: UserEventLimit;

  userController: AbstractControl;

  @Input() keepNativeInput = true;
  @Input() minimumSelection = 0;

  @Input() userIsAdminOrChair: boolean = false;

  activeSpinner: boolean = false;
  public noUsersFound: boolean = false;
  private searchedWord: string;
  private inputHasValue : boolean = false;

  constructor(
    private userService: UserService,
    public infoService: InfoService,
    private translate: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private adminService: AdminService,
    private route: ActivatedRoute
  ) { }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.userController = this.formGroupController.get(this.name);
      this.userController.setValue([]);

      if (this.userController.value?.length === 0) {
        // User control needs values to initialize.
        const users: User[] = [];

        if (this.includeLoggedUser) {
          this.userService.getUserFromToken().subscribe({
            next: user => {
              users.push(user);
            },
            complete: () => {
              this.userController.setValue(users);
              if (this.minimumSelection > 0){
                this.userController.setValidators(this.minimumSelectionValidator(this.minimumSelection));
              }
            }
          });
        }

        if (this.includeUsers) {
          for (let i in this.includeUsers) {
            this.userService.getUser(
              this.includeUsers[i].id
            ).subscribe({
              next: user => {
                users.push(user);
              },
              complete: () => {
                this.userController.setValue(users);
                if (this.minimumSelection > 0){
                  this.userController.setValidators(this.minimumSelectionValidator(this.minimumSelection));
                }
              }
            });
          }
        }

        this.userController.setValue(users);
      }
      this.stateCtrl = new FormControl('');

      // Get users according to input
      this.stateCtrl.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged()
        ).subscribe(value => {
          this.noUsersFound = false;
          this.inputHasValue = value.length > 0;
          if (value.length >= MIN_LENGTH_SEARCH) {
            this.searchedWord = value;
            this.$filteredUsers?.unsubscribe();
            this.activeSpinner = true;
            this.filteredUsers = this.userService.search(value, this.userEventLimit);
            
            this.filteredUsers.subscribe(users => {
              this.noUsersFound = users.length === 0;
              this.activeSpinner = !(users.length === 0);
              this.searchFoundUsers.emit({ found: users.length > 0, value })
            });

          } else {
            this.searchFoundUsers.emit({ found: false, value: undefined });
            this.filteredUsers = of([]);
          }
          this.includeNewUserOption();
        });
    });
  }

  private includeNewUserOption(): void {
    if (this.enabledNewUserOption) {
      const modelNewUserOption = { id: -1, firstName: this.getOptionLabel() } as User;
  
      this.$filteredUsers = this.filteredUsers.subscribe(users => {
        if (!users.find(user => user.id === -1)) {

          if(this.inputHasValue){
            users.length === 0 ? users = [modelNewUserOption] : users.push(modelNewUserOption);
          }
  
          this.filteredUsers = new Observable<User[]>(observer => {
            observer.next(users);
          });
        }
      });
    }
  }

  private getOptionLabel(): string {
    return this.translate.instant('forms.fields.create-new-user');
  }

  displayFn(user?: User): string | undefined {
    return user.name; // Show nothing on input
  }

  minimumSelectionValidator(minimum : number) {
    return (control : AbstractControl) : {[key: string]: boolean} | null => {
      if(control.value.length < minimum) {
        return { 'minimumSelection': true };
      }
      return null;
    }
  }

  addUser(user: User, { option }: MatAutocompleteSelectedEvent) {
    if (user.id === ID_NEW_USER_OPTION) {
      this.openDialogCreateUser();
      return;
    }

    let users = [];

    if (this.singleUserSearch) {
      users = [user];
      this.selectedOption = option;
      this.clearNativeInput();
    } else {
      users = this.userController.value;
      users.push(user);
      this.clearNativeInput();
    }

    this.addedUser.emit( user );
    this.userController.setValue(users);
    option.deselect();
  }

  private openDialogCreateUser(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '30rem';
    dialogConfig.data = {
      disabledNotification: true
    };

    const dialogRef = this.dialog.open(DialogCreateUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((user: User) => {
      if (user) {
        this.addUserFromExternalComponent(user);
      }
      this.filteredUsers = of([]);
      this.searchInput.nativeElement.blur();
    });
  }

  public addUserFromExternalComponent(user: User) {
    const users = this.userController.value;
    users.push(user);
    this.userController.setValue(users);
    this.addedUser.emit(user);
  }

  deleteUser(index: number): void {
    let newUsers = this.userController.value;
    newUsers.splice(index, 1);
    this.userController.setValue(newUsers);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.userController.value, event.previousIndex, event.currentIndex);
  }

  clearNativeInput() {
    if (this.keepNativeInput) {
      this.input.first.nativeElement.value = null;
    }
  }

  disabledUser(user: User): boolean {
    return !!this.warningUsers.find(u => u.id === user.id);
  }

  getUserProfileImage(user: User) {
    this.userService.getUserProfileImage(user).subscribe(
      profile => {
        return profile.imageUrl;
      }
    );
  }

  public hideEmail(email: string): string {
    return this.authService.hideEmail(email);
  }

  public stopSpinner(): void {
    this.activeSpinner = false;
  }
}